import axios from "axios";

import { WORK_WITH_US_URL } from "../utils/urls";

export const getWorkWithUsData = () => {
  return axios({
    method: "GET",
    url: WORK_WITH_US_URL,
  });
};

export const getWorkWithUsDataByDeptId = (id) => {
  return axios({
    method: "GET",
    url: WORK_WITH_US_URL + "/GetPositionListByDepartmentId" + "/" + id,
  });
};

export const getPositionDetailsById = (id) => {
  return axios({
    method: "GET",
    url: WORK_WITH_US_URL + "/GetJobDetails" + "/" + id,
  });
};
