import axios from "axios";

import { OUR_PARTNERS_URL, OUR_PARTNER_DETAILS_URL } from "../utils/urls";

export const getOurPartnerData = () => {
  return axios({
    method: "GET",
    url: OUR_PARTNERS_URL,
  });
};

export const getOurPartnerDetailsData = ({ id }) => {
  return axios({
    method: "GET",
    url: OUR_PARTNER_DETAILS_URL + "/" + id,
  });
};
