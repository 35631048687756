import React, { useEffect, useState } from "react";
import "./productListPage.scss";

import { Link, useLocation, useParams } from "react-router-dom";

// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import CollapsibleComponent from "../../../components/collapsible/CollapsibleComponent";
import Footer from "../../../components/footer/Footer";

import { getProductsCatData } from "../../../apis/products.apis";

export default function ProductList({ props }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // const catId = window.location.pathname.split("/")[2];
  const { productCatId, stateId } = useParams();

  const [productCatData, setProductCatData] = useState([]);
  useEffect(() => {
    setLoading(true);
    getProductsCatData({ stateId, productCatId })
      .then((res) => {
        setProductCatData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [stateId, productCatId]);

  return (
    <div className="productListPage">
      {/* <Header /> */}
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/products" className="common__headerLinkContainer">
            <span className="linkText">Product</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">
              {productCatData?.CATEGORY_NAME ||
                productCatData?.BRAND_NAME ||
                productCatData?.APPLICATION_AREA}{" "}
            </span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>
        <div className="productListPage__container">
          {productCatData?.productList?.length > 0 ? (
            <CollapsibleComponent
              productListData={productCatData}
              id={productCatId}
              isCollapsOpen={true}
            />
          ) : (
            productCatData?.subSegmentList &&
            productCatData?.subSegmentList?.map((item, index) =>
              index === 0 ? (
                <CollapsibleComponent
                  data1={item}
                  id={productCatId}
                  isCollapsOpen={true}
                  key={index}
                />
              ) : (
                <CollapsibleComponent
                  data1={item}
                  id={productCatId}
                  isCollapsOpen={false}
                  key={index}
                />
              )
            )
          )}
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}
