import React from "react";
import "./workWithUsFormPage.scss";

import { Link, useLocation, useParams } from "react-router-dom";

import backIcon from "../../../assets/images/work-with-us/openPositionDetails/back.png";

// components import
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";

const WorkWithUsFormPage = (props) => {
  const { positionId } = useParams();
  const location = useLocation();
  const propsData = location.state;

  console.log("propsData", propsData);

  console.log(props);

  return (
    <div className="workWithUsFormPage">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/workWithUs" className="common__headerLinkContainer ">
            <span className="linkText">Work With Us</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link
            to={`/workWithUs/${positionId}`}
            className="common__headerLinkContainer "
          >
            <span className="linkText">Position List</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Position Details</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="wwufp__container">
          <div className="wwufp__header">
            <span>{propsData?.POSITION}</span>
          </div>

          <div className="wwufp__formContainer">
            <input
              type="text"
              placeholder="First Name"
              className="wwufp__formInput"
            />

            <input
              type="text"
              placeholder="Last Name"
              className="wwufp__formInput"
            />

            <input
              type="number"
              placeholder="Phone"
              className="wwufp__formInput"
            />

            <input
              type="email"
              placeholder="Email"
              className="wwufp__formInput"
            />

            <input
              type="text"
              placeholder="Address"
              className="wwufp__formInput"
            />

            <div className="wwufp__twoItemForm">
              <input
                type="text"
                placeholder="Country"
                className="wwufp__formInput"
              />

              <input
                type="text"
                placeholder="State/Province"
                className="wwufp__formInput"
              />
            </div>

            <div className="wwufp__twoItemForm">
              <input
                type="text"
                placeholder="District"
                className="wwufp__formInput"
              />

              <input
                type="text"
                placeholder="Upazila/Thana"
                className="wwufp__formInput"
              />
            </div>

            <div className="wwufp__twoItemForm">
              <input
                type="text"
                placeholder="City"
                className="wwufp__formInput"
              />

              <input
                type="text"
                placeholder="Zip Code"
                className="wwufp__formInput"
              />
            </div>

            <input
              type="email"
              placeholder="How did you hear about us?"
              className="wwufp__formInput"
            />

            <select
              className="wwufp__formInputSelect"
              defaultValue="Are you willing to relocate?"
            >
              <option
                className="wwufp__selectOption"
                value="Select Position"
                disabled
              >
                Are you willing to relocate?
              </option>

              <option className="wwufp__selectOption" value="Select Position">
                Yes
              </option>

              <option className="wwufp__selectOption" value="Select Position">
                No
              </option>
            </select>
          </div>

          <div className="wwufp__form2">
            <div className="wwufp__form2Checkbox">
              <input type="checkbox" className="wwufp__Checkbox" />
              <span>
                I am authorized to work in this country for any employer, now
                and in the future.
              </span>
            </div>

            <div className="wwufp__form2Checkbox">
              <input type="checkbox" className="wwufp__Checkbox" />
              <span>
                I am authorized to work in this country for my present employer
                only.
              </span>
            </div>

            <div className="wwufp__form2Checkbox">
              <input type="checkbox" className="wwufp__Checkbox" />
              <span>
                I require sponsorship to work in this country, either now or in
                the future.
              </span>
            </div>

            <div className="wwufp__form2Devider">
              <div className="wwufp__form2Checkbox">
                <input type="checkbox" className="wwufp__Checkbox" />
                <span>Other (please describe)</span>
              </div>

              <input
                type="text"
                placeholder="Description of Other work status"
                className="wwufp__formInput"
              />
            </div>

            <textarea placeholder="Your Message" className="wwufp__textarea" />

            <div className="wwufp__form2Checkbox">
              <input type="checkbox" className="wwufp__Checkbox" />
              <span>
                You certify that the information contained in this application
                is correct to the best of your knowledge. You understand that to
                falsify information is grounds for the company refusing to hire
                you or, if hired, your dismissal.
              </span>
            </div>

            <div className="wwufp__socialProfile">
              <span className="wwufp__socialProfileHeader">
                <span>Social profile (optional)</span>
              </span>
              <p>
                Use this option if you want to share your full LinkedIn profile
                in addition to your resume. Please sign in to apply with
                LinkedIn
              </p>

              <input
                type="text"
                placeholder="Url"
                className="wwufp__formInput"
              />
            </div>

            <div className="wwufp__socialProfile">
              <span className="wwufp__socialProfileHeader">
                <span>Add CV/Resume</span>
              </span>

              {/* <input type="file" className="wwufp__fileUploadBtn" /> */}
              <label className="wwufp__fileUploadBtn">
                {" "}
                Browse
                <input type="file" size="60" />
              </label>
            </div>

            <div className="openPositionBottom__BtnContainer">
              <div className="openPositionBottom__BtnContainer__left">
                <Link>
                  <button className="positionDetails__applyBtn">
                    Apply Now
                  </button>
                </Link>

                <Link to="/workWithUs">
                  <button className="positionDetails__prevApply">
                    <img src={backIcon} alt="" />
                    <span>Back to Current Openings</span>
                  </button>
                </Link>
              </div>
              <div className="openPositionBottom__BtnContainer__right">
                <span>Share</span>
                <i className="fas fa-share-alt"></i>
                <i className="fab fa-twitter"></i>
                <i className="fab fa-facebook-f"></i>
                <i className="fab fa-linkedin-in"></i>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default WorkWithUsFormPage;
