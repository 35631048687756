import React from "react";
import "./wrapper2.scss";

const Wrapper2 = ({ children }) => {
  return (
    <div className="wrapper2">
      <div className="wrapperContent2">{children}</div>
    </div>
  );
};

export default Wrapper2;
