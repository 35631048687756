import axios from "axios";

import { NEWS_AND_EVENTS_URL } from "../utils/urls";

export const getNewsAndEventsData = async () => {
  return axios({
    method: "GET",
    url: NEWS_AND_EVENTS_URL,
  });
};

export const getNewsAndEventsDetailData = async ({ id }) => {
  return axios({
    method: "GET",
    url: NEWS_AND_EVENTS_URL + "/GetNewsEventsById" + "/" + id,
  });
};
