import React, { useEffect, useState } from "react";
import "./downloadPage.scss";
import { Link } from "react-router-dom";

// icons
import { GrDocumentDownload } from "react-icons/gr";
import { ImFilePdf } from "react-icons/im";

// apis
import { getDownloadPage } from "../../apis/download.apis";

// components
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../components/common/wrapper/Wrapper";

const DownloadPage = () => {
  const [downloadData, setDownloadData] = useState(null);
  useEffect(() => {
    getDownloadPage()
      .then((res) => {
        // console.log("res", res.data);
        setDownloadData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("downloadData", downloadData);

  return (
    <div className="downloadPage">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link
            to=""
            className="common__headerLinkContainer common__headerLinkContainer__active"
          >
            <span className="linkText">Download</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>
        </div>

        <div className="downloadPage__container">
          <div className="downloadPage__productFilterContainer">
            <div className="downloadPage__pfLeft">
              <div className="downloadPage__filterContainer">
                <div className="dpf__header">
                  <span>Filter</span>
                </div>

                <div className="dpf__filterSection">
                  <div className="dpf__filterSectionHeader">
                    <span>By Type</span>
                  </div>

                  <div className="dpf__filterSectionBody">
                    <div className="dpf__filterOption">
                      <input type="checkbox" name="category" id="tds" />
                      <label htmlFor="category1">TDS</label>
                    </div>

                    <div className="dpf__filterOption">
                      <input type="checkbox" name="category" id="msds" />
                      <label htmlFor="category1">MSDS</label>
                    </div>

                    <div className="dpf__filterOption">
                      <input type="checkbox" name="category" id="category1" />
                      <label htmlFor="category1">Brochure</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="downloadPage__pfRight">
              {downloadData &&
                downloadData.tdslist.map((item, index) => {
                  return (
                    <a
                      className="downloadPage__pfRightItem"
                      href={item.FILE_PATH}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                    >
                      <div className="dsdsd">
                        <ImFilePdf className="s" />
                      </div>

                      <img src={item.ITEM_IMAGE} alt="" />

                      <div className="name">
                        <span>{item.ITEM_NAME}</span>
                        <ImFilePdf className="s" />
                      </div>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default DownloadPage;
