import React from "react";
import "./landingCareer.scss";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import { Link } from "react-router-dom";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";

const LandingCareer = () => {
  return (
    <div className="landingCareer">
      <Wrapper>
        <Link to="/workWithUs" className="landingCareer__conatiner link">
          <div className="landingCareer__conatiner__left">
            <img
              src={require("../../../assets/images/home/Career-1.png")}
              alt=""
            />
          </div>

          <div className="landingCareer__conatiner__right">
            <span className=" endTextGradient">
              Explore available career opportunities
            </span>

            <Link to="/workWithUs" className="landingCareer__btn link">
              <TextIconBtn name="See our opportunities" />
            </Link>
          </div>
        </Link>
      </Wrapper>
    </div>
  );
};

export default LandingCareer;
