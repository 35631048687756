const aboutTeam = [
  {
    id: 1,
    name: "John smith",
    pos: "Sr. Executive",
    img: "https://dummyimage.com/9:16x1080/",
  },
  {
    id: 2,
    name: "John smith",
    pos: "Sr. Executive",
    img: "https://dummyimage.com/9:16x1080/",
  },
  {
    id: 3,
    name: "John smith",
    pos: "Sr. Executive",
    img: "https://dummyimage.com/9:16x1080/",
  },
  {
    id: 4,
    name: "John smith",
    pos: "Sr. Executive",
    img: "https://dummyimage.com/9:16x1080/",
  },
  {
    id: 5,
    name: "John smith",
    pos: "Sr. Executive",
    img: "https://dummyimage.com/9:16x1080/",
  },
  {
    id: 6,
    name: "John smith",
    pos: "Sr. Executive",
    img: "https://dummyimage.com/9:16x1080/",
  },
];

export default aboutTeam;
