import axios from "axios";

import { DEMO_URL } from "../utils/urls";

export const getDemoNews = () => {
  return axios({
    method: "GET",
    url: DEMO_URL,
  });
};
