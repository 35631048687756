import React from "react";
import "./errorPage.scss";

import Footer from "../../components/footer/Footer";

import ErrorImage from "../../assets/images/errorPage/errorPage.png";
// import Carousal from "../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";

const ErrorPage = () => {
  return (
    <div className="errorPage">
      {/* <div className="topMask" />
      <Carousal /> */}
      <div className="errorPage__container">
        <img src={ErrorImage} alt="" />
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
