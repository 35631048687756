import React, { useEffect, useState, useContext } from "react";
import "./header.scss";

import { Link, useNavigate } from "react-router-dom";

import { slide as Menu } from "react-burger-menu";
import { motion, AnimatePresence } from "framer-motion";
import { NavContext } from "../../context/NavContext";

// import image
// import productImg from "../../assets/images/headerImg/productImg.png";
// import broucherImg from "../../assets/images/headerImg/tds.png";

// dummy data
// import productData from "./productData";
// import downloadData from "./downloadData";
// import newsData from "./newsAndEventsData";

export default function Header({ scrollPos }) {
  const navigate = useNavigate();
  const { nav, setNav } = useContext(NavContext);

  const [isOpen, setIsOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);

  // for search
  const [searchValue, setSearchValue] = useState("");
  const handleSearchEnter = (e) => {
    e.preventDefault();
    navigate("/search?q=" + searchValue);
    setSearchValue("");
    expanded && setExpanded(false);
  };

  const handleSearchClick = () => {
    setExpanded(!expanded);
  };

  const [hoveredElement, setHoveredElement] = useState("");

  const setNavHandeller = (nav) => {
    setNav(nav);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position > 700) {
      setIsOpen(false);
      setExpanded(false);
      document.getElementById("top").style.position = "fixed";
    } else {
      setIsOpen(true);
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (scrollPos > 700) {
      setIsOpen(false);
      setExpanded(false);
      document.getElementById("top").style.position = "fixed";
    } else {
      setIsOpen(true);
      setExpanded(false);
    }
  }, [scrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="headerContainer" id="top">
      <div className="sidebarMobo">
        <Menu
          customBurgerIcon={
            <div
              style={{
                display: "flex",
                justifyItems: "flex-end",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "#ffbf0f",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                menu
              </span>
              <svg
                className="customBurgerIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffbf0f"
                // stroke-width="2"
                // stroke-linecap="round"
                // stroke-linejoin="round"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="21" y1="10" x2="7" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="21" y1="18" x2="7" y2="18"></line>
              </svg>
            </div>
          }
        >
          <a href="/about" className="menu-item">
            ABOUT TOCOMA
          </a>

          <a href="/products" className="menu-item">
            PRODUCTS
          </a>

          <a href="/reference" className="menu-item">
            REFERENCE PROJECTS
          </a>

          <a href="/partners" className="menu-item">
            OUR PARTNERS
          </a>
          {/* <a href="/news&events" className="menu-item">
            NEWS AND EVENTS
          </a> */}

          <a href="/workWithUs" className="menu-item">
            WORK WITH US
          </a>

          <a href="/download" className="menu-item">
            DOWNLOADS
          </a>

          <a href="/contactUs" className="menu-item">
            CONTACT
          </a>
        </Menu>
      </div>

      <div className="mySidenav">
        <div className="sidebarContainer">
          <div className="left">
            <Link to="/" className="headerLogo__container">
              <img
                src={require("../../assets/tocoma-logo-update.png")}
                alt=""
              />
            </Link>
          </div>

          <div className="right">
            <AnimatePresence>
              {expanded && (
                <motion.div
                  className="content"
                  initial={{
                    opacity: 0,
                    x: 100,
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    zIndex: 100,
                  }}
                  exit={{
                    opacity: 0,
                    x: 100,
                  }}
                  transition={{ duration: 0.5 }}
                  //exit transition not working
                >
                  <div className="search">
                    <form
                      action=""
                      type="submit"
                      onSubmit={handleSearchEnter}
                      className="search__inputForm"
                    >
                      <input
                        type="text"
                        className="search__input"
                        aria-label="search"
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />

                      <i
                        className={
                          expanded
                            ? "fa-solid fa-times search__icon"
                            : "fa-solid fa-search search__icon"
                        }
                        onClick={handleSearchClick}
                      ></i>
                    </form>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {isOpen && (
                <motion.div
                  className="top"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "45px" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="topContainer">
                    <div
                      className="search-container"
                      onClick={handleSearchClick}
                    >
                      <input
                        type="number"
                        placeholder="Search.."
                        name="search"
                      />
                      <i className="fa fa-search"></i>
                    </div>

                    {/* <div className="shopLogo">
                      <img
                        src={require("../../assets/tocoma-shop.png")}
                        alt="shop"
                      />
                    </div>

                    <div className="topItemOpt">
                      <div className="topItem languageSelect">
                      <i className="fa-solid fa-globe"></i>
                      <select name="language" id="language">
                        <option value="en">ENGLISH</option>
                        <option value="bn">BANGLA</option>
                      </select>
                    </div>
                      <div className="topItem">
                        <i className="fa-solid fa-user"></i>
                        Login
                      </div>
                    </div> */}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <ul className="bottom">
              {/* {hoveredElement} */}

              <Link
                onMouseEnter={() => {
                  setHoveredElement("about");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
                onClick={() => {
                  setNavHandeller("about");
                }}
                className={
                  nav === "about"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "about"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                to="/about"
              >
                ABOUT TOCOMA
              </Link>

              <Link
                className={
                  nav === "products"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "products"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                onMouseEnter={() => {
                  setHoveredElement("products");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
                onClick={() => {
                  setNavHandeller("products");
                }}
                to="/products"
              >
                PRODUCTS
              </Link>

              <Link
                to="/reference"
                className={
                  nav === "reference"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "reference"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                onClick={() => {
                  setNavHandeller("reference");
                }}
                onMouseEnter={() => {
                  setHoveredElement("reference");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
              >
                REFERENCE PROJECTS
              </Link>

              <Link
                onMouseEnter={() => {
                  setHoveredElement("dealers");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
                className={
                  nav === "dealers"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "dealers"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                onClick={() => {
                  setNavHandeller("dealers");
                }}
                // to="/dealers"
                to="/partners"
              >
                OUR Partners
              </Link>

              {/* <Link
                className={
                  nav === "news&events"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "news&events"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                onMouseEnter={() => {
                  setHoveredElement("news&events");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
                onClick={() => {
                  setNavHandeller("news&events");
                }}
                to="/news&events"
              >
                NEWS and EVENTS
              </Link> */}

              <Link
                onMouseEnter={() => {
                  setHoveredElement("workWithUs");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
                className={
                  nav === "workWithUs"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "workWithUs"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                onClick={() => {
                  setNavHandeller("workWithUs");
                }}
                to="/workWithUs"
              >
                Work with us
              </Link>

              <Link
                onMouseEnter={() => {
                  setHoveredElement("download");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
                className={
                  nav === "download"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "download"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                onClick={() => {
                  setNavHandeller("download");
                }}
                to="/download"
              >
                DOWNLOADS
              </Link>

              <Link
                className={
                  nav === "contactUs"
                    ? "whitespace-nowrap link"
                    : hoveredElement === "contactUs"
                    ? "whitespace-nowrap link"
                    : "whitespace-nowrap notHovered link"
                }
                onMouseEnter={() => {
                  setHoveredElement("contactUs");
                }}
                onMouseLeave={() => {
                  setHoveredElement("");
                }}
                onClick={() => {
                  setNavHandeller("contactUs");
                }}
                to="/contactUs"
              >
                CONTACT
              </Link>

              {!isOpen && (
                <li className="whitespace-nowrap link">
                  <i
                    className="fa-solid fa-search"
                    onClick={handleSearchClick}
                  ></i>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* {expanded && (
        <div className="full-screen-overlay">
          <AnimatePresence>
            {expanded && (
              <motion.div
                className="overlay-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.95 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="overlay__content__inner">
                  <div className="overlay__content__innerBlock">
                    <div className="overlay__content__innerBlock__contentContainer">
                      <div className="overlay__content__innerBlock__contentContainer__Block">
                        <div className="innerBlock__productContent__titele">
                          <h1>Products</h1>
                        </div>

                        {productData.map((item, index) => {
                          return (
                            <div className="productContent__container">
                              <div className="productContent__container__left">
                                <div className="productContent__container__leftImg">
                                  <img src={item.img} alt="" />
                                </div>
                              </div>

                              <div className="productContent__container__right1">
                                <div className="productContent__container__right__title1">
                                  <span>{item.title}</span>
                                </div>
                                <div className="productContent__container__right__desc">
                                  <span>{item.desc}</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="overlay__content__innerBlock__contentContainer__Block">
                        <div className="innerBlock__productContent__titele">
                          <h1>Brochure</h1>
                        </div>

                        {downloadData.map((item, index) => {
                          return (
                            <div className="productContent__container">
                              <div className="BrochureContent__container__left">
                                <div className="BrochureContent__container__leftImg">
                                  <img src={broucherImg} alt="" />
                                </div>
                              </div>

                              <div className="productContent__container__right1">
                                <div className="productContent__container__right__title1">
                                  <span>Segment product Broucher</span>
                                </div>
                                <div className="productContent__container__right__desc">
                                  <span>
                                    We create, innovate and install better
                                    construction products that build a brighter
                                    future.
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="overlay__content__innerBlock__contentContainer__Block">
                        <div className="innerBlock__productContent__titele">
                          <h1>News And Events</h1>
                        </div>

                        {newsData.map((item, index) => {
                          return (
                            <div className="sNewsContent__container">
                              <div className="sNewsContent__container__title">
                                <span>{item.title}</span>
                              </div>
                              <div className="sNewsContent__container__desc">
                                <span>{item.desc}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )} */}
    </div>
  );
}
