const branchData = [
  {
    id: 1,
    name: "Head Office",
    address:
      "D3A-10, Block D3A, Dana 1 Commercial Centre, Jalan PJU 1A/46, 47301 Petaling Jaya, Selangor - MALAYSIA",
    phone: "+91 80 2544 4444",
  },
  {
    id: 2,
    name: "Branch Office",
    address:
      "D3A-10, Block D3A, Dana 1 Commercial Centre, Jalan PJU 1A/46, 47301 Petaling Jaya, Selangor - MALAYSIA",
    phone: "+91 80 2544 4444",
  },
  {
    id: 3,
    name: "Branch Office",
    address:
      "D3A-10, Block D3A, Dana 1 Commercial Centre, Jalan PJU 1A/46, 47301 Petaling Jaya, Selangor - MALAYSIA",
    phone: "+91 80 2544 4444",
  },
];

export default branchData;
