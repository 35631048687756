import React from "react";
import "./footer.scss";

// components import
import Wrapper2 from "../common/wrapper2/Wrapper2";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <Wrapper2>
        <div className="footerContainer">
          <div className="footer__topContainer">
            <div className="footer__topContainer__left">
              <div className="footer__topContainerLeft__companyDetailsContainer">
                <div className="footer__companyNameHeader">
                  <span>TOCOMA</span>
                </div>

                <div className="footer__companyDetailsTextAndIconContainer">
                  <div className="footer__companyDetailsIconr">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <div className="footer__companyDetailsTextWithHeader">
                    <h1>Head Office</h1>
                    <span className="footer__smtext">
                      1005 Mailbagh Chowdhury para, Khilgaon, Dhaka-1219,
                      Bangladesh
                    </span>
                  </div>
                </div>

                <div className="footer__companyDetailsTextAndIconContainer">
                  <div className="footer__companyDetailsIconr">
                    <i className="fa-solid fa-envelope"></i>
                  </div>
                  <div className="footer__companyDetailsText">
                    <a
                      className="footer__smtext"
                      href="mailto:info@tocoma.bd.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      info@tocoma.bd.com
                    </a>
                  </div>
                </div>

                <div className="footer__companyDetailsTextAndIconContainer">
                  <div className="footer__companyDetailsIconr">
                    <i className="fa-solid fa-phone"></i>
                  </div>
                  <div className="footer__companyDetailsText">
                    <a className="footer__smtext" href="tel:+8801812120000">
                      +8801812120000,
                    </a>
                    <a className="footer__smtext" href="tel:+8801767456459">
                      +8801767456459
                    </a>
                  </div>
                </div>

                <div className="footer__socialIconContainer">
                  <a
                    className="footer__socialIcon"
                    href="https://twitter.com/tocoma_bd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    className="footer__socialIcon"
                    href="https://www.facebook.com/tocoma.bd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="footer__socialIcon"
                    href="https://www.instagram.com/tocoma.bd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="footer__socialIcon"
                    href="https://www.linkedin.com/company/tocoma-bd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              
              <div className="footer__topContainerLeft__linkContainer">
                <div className="footer__linkHeader">
                  <h1>Quick Links</h1>
                </div>

                <div className="footer__linkContainer">
                  <div className="footer__linkContainer__left">
                    <Link to="/about" className="footer__link">
                      About Us
                    </Link>
                    <Link to="/services" className="footer__link">
                      Product & Solution
                    </Link>
                    <Link to="/services" className="footer__link">
                      Applications
                    </Link>
                    <Link to="/services" className="footer__link">
                      Service
                    </Link>
                    <Link to="/services" className="footer__link">
                      Project
                    </Link>
                    <Link to="/services" className="footer__link">
                      News & Event
                    </Link>
                    <Link to="/services" className="footer__link">
                      Career
                    </Link>
                    <Link to="/services" className="footer__link">
                      Contact
                    </Link>
                  </div>

                  <div className="footer__linkContainer__right">
                    <Link to="/about" className="footer__link">
                      About Us
                    </Link>
                    <Link to="/services" className="footer__link">
                      Product & Solution
                    </Link>
                    <Link to="/services" className="footer__link">
                      Applications
                    </Link>
                    <Link to="/services" className="footer__link">
                      Service
                    </Link>
                    <Link to="/services" className="footer__link">
                      Project
                    </Link>
                    <Link to="/services" className="footer__link">
                      News & Event
                    </Link>
                    <Link to="/services" className="footer__link">
                      Career
                    </Link>
                    <Link to="/services" className="footer__link">
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__topContainer__right">
              <div className="footer__topContainer__right__img">
                <img
                  src={require("../../assets/images/footer/footer-btext.png")}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper2>

      <div className="footer__bottomContainer">
        <Wrapper2>
          <span>© Copyright Tocoma Limited 2022. All Right Reserved</span>
        </Wrapper2>

        <img
          src={require("../../assets/images/footer/Footer_Base_line.Png")}
          alt="logo"
        />
      </div>
    </div>
  );
}
