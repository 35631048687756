import React, { useEffect, useState } from "react";
import "./partnersPage.scss";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

// components import
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import Footer from "../../../components/footer/Footer";

// api import
import { getOurPartnerData } from "../../../apis/ourPartners.apis";
import PrimaryBtnWithBorder from "../../../components/common/buttons/primaryBtnWithBorder/PrimaryBtnWithBorder";

const PartnersPage = () => {
  const [ourPartners, setOurPartners] = useState([]);

  useEffect(() => {
    getOurPartnerData()
      .then((res) => {
        setOurPartners(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(ourPartners);

  return (
    <>
      {/* <Header /> */}
      <div className="topMask" />
      <div id="landingPage">
        <Carousal />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to="/partners"
              className="common__headerLinkContainer common__headerLinkContainer__active"
            >
              <span className="linkText">Our Partners</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>
          </div>

          <section className="dealers">
            <div className="dealers__container">
              <div className="dealer_grid">
                {ourPartners?.map((item, index) => (
                  <Link
                    to={`/partners/${item.BRAND_ID}`}
                    className="dealer_card link"
                    key={item.id}
                  >
                    <div>
                      <div
                        {...(item.id === 1
                          ? {
                              style: {
                                height: "150px",
                              },
                            }
                          : {})}
                        className="dealer_img"
                      >
                        <img
                          {...(item.id === 1
                            ? {
                                style: {
                                  height: "150px",
                                },
                              }
                            : {})}
                          src={item?.IMAGE_LINK}
                          // src={`https://www.tocoma.co/${item?.IMAGE_LINK}`}
                          alt=""
                        />
                      </div>

                      <div className="dealer_content">
                        {item?.DESCRIPTION &&
                          parse(
                            item?.DESCRIPTION.length > 175
                              ? item?.DESCRIPTION.substring(0, 175) + "..."
                              : item?.DESCRIPTION
                          )}
                      </div>
                    </div>

                    <div className="dealer_discover_btn">
                      <PrimaryBtnWithBorder text="DISCOVER" />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </section>
        </Wrapper>
      </div>
      <Footer />
    </>
  );
};

export default PartnersPage;
