import { useState, createContext } from "react";

// create context with default value and updated features of the context
export const NavContext = createContext({
  nav: "home",
  setNav: () => {},
});

// create provider for the context
export const NavProvider = ({ children }) => {
  const [nav, setNav] = useState("home");

  return (
    <NavContext.Provider value={{ nav, setNav }}>
      {children}
    </NavContext.Provider>
  );
};

// old code
// export const NavContext = createContext();

// export const NavProvider = ({ children }) => {
//     const [nav, setNav] = useState("home");

//     return (
//         <NavContext.Provider value={{ nav, setNav }}>
//             {children}
//         </NavContext.Provider>
//     );
// };
