import React, { useEffect, useState } from "react";
import "./openPositionDetails.scss";

import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

// components import
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import Footer from "../../../components/footer/Footer";

// images import
import backIcon from "../../../assets/images/work-with-us/openPositionDetails/back.png";

// api import
import { getPositionDetailsById } from "../../../apis/workWithUs.apis";

const OpenPositionDetails = () => {
  const { positionId } = useParams();
  const { workDeptId } = useParams();

  const [positionDetails, setPositionDetails] = useState({});
  useEffect(() => {
    getPositionDetailsById(positionId)
      .then((res) => {
        setPositionDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [positionId]);
  console.log("positionDetails", positionDetails);

  console.log("positionId", positionDetails.JOB_DESCRIPTION_ID);

  return (
    <div className="openPositionDetails">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/workWithUs" className="common__headerLinkContainer ">
            <span className="linkText">Work With Us</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link
            to={`/workWithUs/${workDeptId}`}
            className="common__headerLinkContainer "
          >
            <span className="linkText">Position List</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Position Details</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>
      </Wrapper>

      <div className="openPositionDetails__container">
        <Wrapper>
          <div className="openPositionDetails__content">
            <div className="penPositionDetails__headerAndBtn">
              <div className="openPositionDetails__header">
                <span>{positionDetails.POSITION}</span>
              </div>
              <div className="openPositionDetails__btn">
                <Link
                  to={`/workWithUs/${workDeptId}/${positionId}/${positionDetails.JOB_DESCRIPTION_ID}`}
                  state={positionDetails}
                  className="linkBtn"
                >
                  <button>Apply</button>
                </Link>
              </div>
            </div>

            <div className="openPositionDetails__positionInfoContainer">
              <div className="positionInfo">
                <div className="positionInfo__header">
                  <span>Location:</span>
                </div>
                <div className="positionInfo__content">
                  <span>{positionDetails?.JOB_LOCATION}</span>
                </div>
              </div>

              <div className="positionInfo">
                <div className="positionInfo__header">
                  <span>Job Category:</span>
                </div>
                <div className="positionInfo__content">
                  <span>{positionDetails?.DEPARTMENT_NAME}</span>
                </div>
              </div>

              <div className="positionInfo">
                <div className="positionInfo__header">
                  <span>Brand:</span>
                </div>
                <div className="positionInfo__content">
                  <span>TOCOMA Limited</span>
                </div>
              </div>

              <div className="positionInfo">
                <div className="positionInfo__header">
                  <span>Job Type:</span>
                </div>
                <div className="positionInfo__content">
                  <span>{positionDetails?.EMPLOYMENT_STATUS}</span>
                </div>
              </div>

              <div className="positionInfo">
                <div className="positionInfo__header">
                  <span>Work Location:</span>
                </div>
                <div className="positionInfo__content">
                  <span>{positionDetails?.JOB_LOCATION}</span>
                </div>
              </div>

              <div className="positionInfo">
                <div className="positionInfo__header">
                  <span>Contract Type:</span>
                </div>
                <div className="positionInfo__content">
                  <span>{positionDetails?.CONTRACT_TYPE}</span>
                </div>
              </div>
            </div>

            <div className="openPosition__commonHeaderAndContent">
              <div className="openPosition__commonHeader">
                <span>Who We Are</span>
              </div>
              <div className="openPosition__commonContent">
                <span>
                  {positionDetails?.WHO_WE_ARE &&
                    parse(positionDetails?.WHO_WE_ARE)}
                </span>
              </div>
            </div>

            <div className="openPosition__commonHeaderAndContent">
              <div className="openPosition__commonHeader">
                <span>job summary</span>
              </div>
              <div className="openPosition__commonContent">
                <span>
                  <span>
                    {positionDetails?.JOB_SUMMERY &&
                      parse(positionDetails?.JOB_SUMMERY)}
                  </span>
                </span>
              </div>
            </div>

            <div className="openPosition__commonHeaderAndContent">
              <div className="openPosition__commonHeader">
                <span>Responsibilities</span>
              </div>
              <div className="openPosition__commonContent">
                <span>
                  {positionDetails?.RESPONSIBILITIES &&
                    parse(positionDetails?.RESPONSIBILITIES)}
                </span>
              </div>
            </div>

            <div className="openPosition__commonHeaderAndContent">
              <div className="openPosition__commonHeader">
                <span>Experience</span>
              </div>
              <div className="openPosition__commonContent">
                <span>
                  {positionDetails?.EXPERIANCE &&
                    parse(positionDetails?.EXPERIANCE)}
                </span>
              </div>
            </div>

            <div className="openPosition__commonHeaderAndContent">
              <div className="openPosition__commonHeader">
                <span>Join our team</span>
              </div>
              <div className="openPosition__commonContent">
                <span>
                  {positionDetails?.JOB_SUMMERY &&
                    parse(positionDetails?.JOIN_OUR_TEAM)}
                </span>
              </div>
            </div>

            <div className="prefferContactAndBtnContainer">
              <div className="prefferContact">
                <span>
                  {positionDetails?.APPLY_INSTRUCTION &&
                    parse(positionDetails?.APPLY_INSTRUCTION)}
                </span>
              </div>

              <div className="openPositionBottom__BtnContainer">
                <div className="openPositionBottom__BtnContainer__left">
                  <Link
                    to={`/workWithUs/${workDeptId}/${positionId}/${positionDetails.JOB_DESCRIPTION_ID}`}
                    state={positionDetails}
                  >
                    <button className="positionDetails__applyBtn">
                      Apply Now
                    </button>
                  </Link>

                  <Link to="/workWithUs">
                    <button className="positionDetails__prevApply">
                      <img src={backIcon} alt="" />
                      <span>Back to Current Openings</span>
                    </button>
                  </Link>
                </div>
                <div className="openPositionBottom__BtnContainer__right">
                  <span>Share</span>
                  <i className="fas fa-share-alt"></i>
                  <i className="fab fa-twitter"></i>
                  <i className="fab fa-facebook-f"></i>
                  <i className="fab fa-linkedin-in"></i>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>

      <Footer />
    </div>
  );
};

export default OpenPositionDetails;
