import React, { useRef, useState } from "react";
import "./landingAbout.scss";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import SwiperCore, { Scrollbar } from "swiper";

import Wrapper from "../../../components/common/wrapper/Wrapper";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";
import ReactSlider from "react-slider";

const HomeAbout = ({ data }) => {
  SwiperCore.use([Scrollbar]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const swiperRef = useRef(null);

  const updateCurrentSlide = () => {
    setCurrentSlide(swiperRef.current.swiper.realIndex);
  };

  return (
    <div className="homeAbout">
      <Wrapper>
        <div className="homeAbout__container">
          <div className="homeAboutTitleAndIcon">
            <span className="homeAbout__title">About TOCOMA</span>
            {/* <img
              src={require("../../../assets/images/home/About Underline.png")}
              alt=""
            /> */}
          </div>

          <Swiper
            className="homeAbout__swiper"
            loop={true}
            // slidesPerView={3}
            spaceBetween={30}
            ref={swiperRef}
            onSlideChange={(swiper) => {
              updateCurrentSlide();
            }}
            onSwiper={(swiper) => {
              setTotalSlides(swiper.slides.length);
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
          >
            {data?.map((slide, index) => {
              return (
                <SwiperSlide className="homeAbout__swiperSlide" key={index}>
                  <Link
                    to="/about"
                    className="homeAbout__swiperSlideContainer link"
                  >
                    {index % 2 === 1 && (
                      <div className="homeAbout__slideContent">
                        <span className="homeAbout__slideTitle">
                          {slide.BANNER_TITLE}
                        </span>
                        <span className="homeAbout__slideDesc">
                          {parse(slide.DESCRIPTION)}
                        </span>
                      </div>
                    )}
                    <div className="homeAbout__slideImage">
                      <img src={slide.BANNER_IMAGE_LINK} alt="" />
                    </div>

                    {index % 2 === 0 && (
                      <div className="homeAbout__slideContent">
                        <span className="homeAbout__slideTitle">
                          {slide.BANNER_TITLE}
                        </span>
                        <span className="homeAbout__slideDesc">
                          {parse(slide.DESCRIPTION.slice(0, 100) + "...")}
                        </span>
                      </div>
                    )}
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="homeAbout__scrollbar1">
            <ReactSlider
              className="slider"
              thumbClassName="thumb"
              trackClassName="track"
              defaultValue={currentSlide}
              onChange={(value) => {
                setCurrentSlide(value);
                swiperRef.current.swiper.slideTo(value, 500);
              }}
              min={0}
              max={data.length - 1}
              value={currentSlide}
            />
          </div>

          <div className="homeAbout__btn">
            <Link to="/about" className="link">
              <TextIconBtn name="Discover" />
            </Link>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default HomeAbout;
