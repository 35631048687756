import React, { useEffect, useState } from "react";
import "./aboutPage.scss";

import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import aboutTeam from "./teamData";

// componets import
// import Carousal from "../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../components/common/wrapper/Wrapper";
import Footer from "../../components/footer/Footer";
import TextIconBtn from "../../components/common/buttons/textIconBtn/TextIconBtn";
import AboutTocoma from "../../components/commonPageComponent/aboutTocoma/AboutTocoma";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

// api call
import { getAboutHistoryData } from "../../apis/aboutTocoma.apis";
import { getAboutData } from "../../apis/aboutTocoma.apis";

export default function AboutPage() {
  const [swiper, setSwiper] = useState(null);

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleShiftLeft = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleShiftRight = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        if (swiper.isBeginning) {
          setIsBeginning(true);
          setIsEnd(false);
        } else if (swiper.isEnd) {
          setIsBeginning(false);
          setIsEnd(true);
        } else {
          setIsBeginning(false);
          setIsEnd(false);
        }
      });
    }
  }, [swiper]);

  // History Evvent
  const [activeEvent, setActiveEvent] = useState(0);
  const handlEventClick = (e) => {
    setActiveEvent(e);
  };

  // History Data
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    getAboutHistoryData()
      .then((res) => {
        setHistoryData(res.data);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // About Data
  const [aboutData, setAboutData] = useState([]);
  useEffect(() => {
    getAboutData()
      .then((res) => {
        setAboutData(res.data[0]);
        // setAboutData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("aboutData", aboutData);

  return (
    <div className="aboutPage">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">About Tocoma</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="aboutContainerTop">
          <div className="aputPage__about">
            <h2>{aboutData?.BANNER_TITLE}</h2>
            <span>
              {aboutData.DESCRIPTION && parse(aboutData?.DESCRIPTION)}
            </span>
          </div>

          <AboutTocoma />

          <div className="aboutVideoContainer">
            <div className="aboutVideo">
              <img
                src={require("../../assets/images/about/Video.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </Wrapper>

      <div className="aboutPage__history">
        <Wrapper>
          <div className="historyContent__container">
            <div className="historyContent__HeaderAndContent">
              <div className="historyHeader">
                <span className="historyHeader__text">Our History</span>
                <img
                  src={require("../../assets/images/about/Our History Under Line.png")}
                  alt=""
                />
              </div>

              <motion.div
                className="aboutPage__historyContent"
                key={activeEvent}
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -20, opacity: 0 }}
                transition={{ duration: 0.7 }}
              >
                {historyData.length > 0 && (
                  <>
                    <div className="historyContent__left">
                      <div className="historyContent_ImageSection">
                        <img
                          src={historyData[activeEvent]?.IMAGE_LINK}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="historyContent__right">
                      <span className="historyDate">
                        {historyData[activeEvent]?.YEAR}-
                        {historyData[activeEvent]?.MONTH}
                      </span>
                      <h2 className="categoryName">
                        {historyData[activeEvent]?.TITLE}
                      </h2>
                      <span className="historyParagraph">
                        {parse(
                          historyData[activeEvent].DESCRIPTION.slice(0, 400) +
                            "..."
                        )}
                      </span>
                    </div>
                  </>
                )}
              </motion.div>
            </div>

            <div className="historyEvent">
              <div className="barContainer">
                <i className="fa-sharp fa-solid fa-angle-left icon-h1" />

                <div className="barLine"></div>
                {historyData.map((item, index) => {
                  return (
                    <>
                      <div className="barBoxContainer" key={index}>
                        <span
                          className={
                            activeEvent === index ? "month activeText" : "month"
                          }
                        >
                          {item.MONTH.slice(0, 3)}
                        </span>
                        <div
                          className={
                            activeEvent === index ? "barBox active" : "barBox"
                          }
                          onClick={() => handlEventClick(index)}
                        ></div>
                        <span
                          className={
                            activeEvent === index ? "year activeText" : "year"
                          }
                        >
                          {item.YEAR}
                        </span>
                      </div>

                      <div className="barLine"></div>
                    </>
                  );
                })}

                <i className="fa-solid fa-angle-right icon-h1" />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>

      <Wrapper>
        <section className="aboutPage__teamAndclient">
          <div className="aboutPage__team">
            <div className="aboutCompHeader">
              <span>Meet Our Team</span>
            </div>

            <div className="aboutPage__teamSwiperContainer">
              <Swiper
                className="swipper-wrapper aboutPage__teamSwiper"
                modules={[Pagination, Navigation]}
                onSwiper={setSwiper}
                slidesPerView={4}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  500: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1100: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
              >
                {aboutTeam?.map((slide, index) => (
                  <SwiperSlide
                    className="aboutPage__teamSwiperSlide"
                    key={index}
                  >
                    <div className="teamSwiperSlide__image">
                      <img src={slide?.img} alt="" />
                    </div>

                    <div className="teamSwiperSlide__content">
                      <div className="teamSwiperSlide__contentText">
                        <span className="teamSwiperSlide__contentTextName">
                          John Smith
                        </span>
                        <span className="teamSwiperSlide__contentTextPos">
                          Position
                        </span>
                      </div>

                      <div className="teamSwiperSlide__contentIcon">
                        <i className="fa-brands fa-twitter"></i>
                        <i className="fa-brands fa-linkedin-in"></i>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="aboutPage__swiperBtnCont">
                <img
                  className={
                    isBeginning === true
                      ? "aboutPage__swiperBtn deactiveBtn"
                      : "aboutPage__swiperBtn"
                  }
                  src={require("../../assets/images/home/left right.png")}
                  alt=""
                  onClick={handleShiftLeft}
                />

                <img
                  className={
                    isEnd === true
                      ? "aboutPage__swiperBtn deactiveBtn"
                      : "aboutPage__swiperBtn"
                  }
                  src={require("../../assets/images/home/left right.png")}
                  alt=""
                  onClick={handleShiftRight}
                />
              </div>
            </div>
          </div>

          <div className="aboutPage__Clients">
            <div className="aboutPage__clientsContainer">
              <div className="aboutPage__clientsHeaderNadIcon">
                <span>Our Clients</span>

                <img
                  src={require("../../assets/images/about/Client Under Line.png")}
                  alt=""
                />
              </div>

              <div className="aboutPage__clientsList">
                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 01.jpg")}
                    alt="our-partner-01"
                  />
                </div>

                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 02.jpg")}
                    alt="our-partner-02"
                  />
                </div>

                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 03.jpg")}
                    alt="our-partner-03"
                  />
                </div>

                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 04.jpg")}
                    alt="our-partner-04"
                  />
                </div>

                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 05.jpg")}
                    alt="our-partner-05"
                  />
                </div>

                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 06.jpg")}
                    alt="our-partner-05"
                  />
                </div>

                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 07.jpg")}
                    alt="our-partner-05"
                    className="w-full"
                  />
                </div>

                <div className="aboutPage__clientsListItem">
                  <img
                    src={require("../../assets/images/about/CLient 08.jpg")}
                    alt="our-partner-05"
                    className="w-full"
                  />
                </div>
              </div>

              <div className="aboutPage__clientsBtn">
                <TextIconBtn name="Explore All" />
              </div>
            </div>
          </div>
        </section>
      </Wrapper>

      <Footer />
    </div>
  );
}
