import axios from "axios";

import {
  REFERENCE_PROJECT_LANDING_PAGE_URL,
  REFERENCE_PROJECT_URL,
  REFERENCE_PROJECT_TYPE_URL,
  REFERENCE_PROJECT_DETAILS_URL,
} from "../utils/urls";

export const getReferenceProjectLandingPageData = () => {
  return axios({
    method: "GET",
    url: REFERENCE_PROJECT_LANDING_PAGE_URL,
  });
};

export const getReferenceProjectData = () => {
  return axios({
    method: "GET",
    url: REFERENCE_PROJECT_URL,
  });
};

export const getReferenceProjectTypeData = ({ id }) => {
  return axios({
    method: "GET",
    url: REFERENCE_PROJECT_TYPE_URL + "/" + id,
  });
};

export const getReferenceProjectDetailData = ({ typeId, pId }) => {
  return axios({
    method: "GET",
    url: REFERENCE_PROJECT_DETAILS_URL + "/" + typeId + "/" + pId,
  });
};
