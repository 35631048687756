import React from "react";
import "./aboutDetailPage.scss";

// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import { Link, useNavigate } from "react-router-dom";

const AboutDetailPage = () => {
  const navigate = useNavigate();
  return (
    <div className="aboutDetailPage">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/about" className="common__headerLinkContainer">
            <span className="linkText">About Page</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div
            className="common__headerLinkContainer common__headerLinkContainer__active"
            onClick={() => navigate(-1)}
          >
            <span className="linkText">About Detail</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default AboutDetailPage;
