import React from "react";
import "./iconBtn.scss";

const IconBtn = ({ white, name }) => {
  return (
    <>
      {white ? (
        <div className="iconBtnWhite">
          <svg
            // width="56"
            // height="24"
            viewBox="0 0 57 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Layer_1" clip-path="url(#clip0_228_537)">
              <path
                id="Vector_1"
                d="M4.39147 18L11.3822 5.99438H6.99072L0 18H4.39147Z"
                fill="#fff"
              />
              <path
                id="Vector_2"
                d="M17.755 18L24.7457 5.99438H18.0723L11.0815 18H17.755Z"
                fill="#fff"
              />
              <path
                id="Vector_3"
                d="M32.3433 18L39.334 5.99438H40.8145L44.5937 11.9999L40.8256 18H32.3433Z"
                fill="#fff"
              />
              <path
                id="Vector_4"
                d="M30.089 18L37.0797 5.99438H29.0815L22.0908 18H30.089Z"
                fill="#fff"
              />
              <path
                id="Vector_5"
                d="M56.3045 11.5602C53.9668 7.84964 51.6347 4.13907 49.3082 0.428494C49.2328 0.291207 49.1188 0.179034 48.9803 0.105834C48.8418 0.0326341 48.6849 0.00162151 48.529 0.0166252C47.1041 0.0166252 45.6793 0.0166252 44.2544 0.0166252H39.952C39.7015 0.0166252 39.4622 0.0166198 39.3231 0.289347C39.1839 0.562074 39.3231 0.734613 39.4511 0.929418L43.3917 7.20214L46.2581 11.7495C46.3172 11.8249 46.3494 11.918 46.3494 12.0138C46.3494 12.1097 46.3172 12.2028 46.2581 12.2782L43.3861 16.8255L39.49 23.0426C39.424 23.1376 39.3662 23.2382 39.3175 23.3431C39.2905 23.4063 39.2778 23.4746 39.2802 23.5433C39.2826 23.6119 39.3001 23.6792 39.3314 23.7403C39.3628 23.8014 39.4072 23.8548 39.4616 23.8968C39.5159 23.9388 39.5788 23.9683 39.6459 23.9832C39.7828 23.9972 39.9208 23.9972 40.0578 23.9832C42.8407 23.9832 45.6681 23.9832 48.4789 23.9832C48.6552 23.9965 48.8314 23.9577 48.9858 23.8716C49.1402 23.7855 49.2658 23.6559 49.3472 23.499C51.6626 19.7884 53.9854 16.0927 56.3156 12.4118C56.4049 12.2872 56.452 12.1374 56.45 11.9842C56.448 11.831 56.397 11.6824 56.3045 11.5602Z"
                fill="#fff"
              />
            </g>
            <defs>
              <clipPath id="clip0_228_537">
                <rect width="56.4545" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      ) : (
        <div className="iconBtnBlack">
          <svg
            viewBox="0 0 57 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Layer_1" clip-path="url(#clip0_228_537)">
              <path
                id="Vector_1"
                d="M4.39147 18L11.3822 5.99438H6.99072L0 18H4.39147Z"
                fill="#010101"
              />
              <path
                id="Vector_2"
                d="M17.755 18L24.7457 5.99438H18.0723L11.0815 18H17.755Z"
                fill="#010101"
              />
              <path
                id="Vector_3"
                d="M32.3433 18L39.334 5.99438H40.8145L44.5937 11.9999L40.8256 18H32.3433Z"
                fill="#010101"
              />
              <path
                id="Vector_4"
                d="M30.089 18L37.0797 5.99438H29.0815L22.0908 18H30.089Z"
                fill="#010101"
              />
              <path
                id="Vector_5"
                d="M56.3045 11.5602C53.9668 7.84964 51.6347 4.13907 49.3082 0.428494C49.2328 0.291207 49.1188 0.179034 48.9803 0.105834C48.8418 0.0326341 48.6849 0.00162151 48.529 0.0166252C47.1041 0.0166252 45.6793 0.0166252 44.2544 0.0166252H39.952C39.7015 0.0166252 39.4622 0.0166198 39.3231 0.289347C39.1839 0.562074 39.3231 0.734613 39.4511 0.929418L43.3917 7.20214L46.2581 11.7495C46.3172 11.8249 46.3494 11.918 46.3494 12.0138C46.3494 12.1097 46.3172 12.2028 46.2581 12.2782L43.3861 16.8255L39.49 23.0426C39.424 23.1376 39.3662 23.2382 39.3175 23.3431C39.2905 23.4063 39.2778 23.4746 39.2802 23.5433C39.2826 23.6119 39.3001 23.6792 39.3314 23.7403C39.3628 23.8014 39.4072 23.8548 39.4616 23.8968C39.5159 23.9388 39.5788 23.9683 39.6459 23.9832C39.7828 23.9972 39.9208 23.9972 40.0578 23.9832C42.8407 23.9832 45.6681 23.9832 48.4789 23.9832C48.6552 23.9965 48.8314 23.9577 48.9858 23.8716C49.1402 23.7855 49.2658 23.6559 49.3472 23.499C51.6626 19.7884 53.9854 16.0927 56.3156 12.4118C56.4049 12.2872 56.452 12.1374 56.45 11.9842C56.448 11.831 56.397 11.6824 56.3045 11.5602Z"
                fill="#010101"
              />
            </g>
            <defs>
              <clipPath id="clip0_228_537">
                <rect width="56.4545" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </>
  );
};

export default IconBtn;
