export const btnData = [
  {
    id: 1,
    title: "Description",
    slug: "product-description",
  },
  {
    id: 2,
    title: "Advantage",
    slug: "advantage",
  },
  {
    id: 3,
    title: "Application Range",
    slug: "application-range",
  },
  {
    id: 4,
    title: "Precaution",
    slug: "precaution",
  },
  {
    id: 5,
    title: "Documents",
    slug: "product-documents",
  },
];
