import React, { useEffect, useState } from "react";
import "./productPage.scss";

import { Link } from "react-router-dom";

// import Carousal from "../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import Footer from "../../../components/footer/Footer";

// product icons
import BrandGrayIcon from "../../../assets/images/productPage/ByBrand__grayIcon.png";
import BrandIcon from "../../../assets/images/productPage/ByBrand__icon.png";
import BySegmentIcon from "../../../assets/images/productPage/BySegment__icon.png";
import BySegmentGrayIcon from "../../../assets/images/productPage/BySegment__grayIcon.png";
import ByApplicationIcon from "../../../assets/images/productPage/ByApplication__icon.png";
import ByApplicationGrayIcon from "../../../assets/images/productPage/ByApplication__grayIcon.png";

// api
import { getProductPageData } from "../../../apis/products.apis";
import IconBtn from "../../../components/common/buttons/iconBtn/IconBtn";

export default function ProductPage() {
  const [selectedProduct, setSelectedProduct] = useState(1);

  // Api Call for getProductsTypeData
  const [productsTypeData, setProductsTypeData] = useState([]);
  useEffect(() => {
    getProductPageData()
      .then((res) => {
        setProductsTypeData(res.data);
        // console.log("productsTypeData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Data Filter
  const [byBrandData, setByBrandData] = useState([]);
  const [byApplicationData, setByApplicationData] = useState([]);
  const [bySegmentData, setBySegmentData] = useState([]);
  useEffect(() => {
    const bySegment = productsTypeData.filter((item) => item.TYPE === "C");
    setBySegmentData(bySegment);

    const byBrand = productsTypeData.filter((item) => item.TYPE === "B");
    setByBrandData(byBrand);

    const byApplication = productsTypeData.filter((item) => item.TYPE === "A");
    setByApplicationData(byApplication);
  }, [productsTypeData]);

  return (
    <div className="ProductPageContainer">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">
              {selectedProduct === 1
                ? "By Brand"
                : selectedProduct === 2
                ? "By Segment"
                : // : selectedProduct === 3
                  // ? "By Application"
                  null}
            </span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="containerItems">
          <div className="containerMin">
            <div className="content">
              <div className="contentTop">
                <div
                  className={
                    selectedProduct === 1
                      ? "contentBtnBox active"
                      : "contentBtnBox"
                  }
                  onClick={() => setSelectedProduct(1)}
                >
                  <div className="c">
                    <img
                      src={selectedProduct === 1 ? BrandIcon : BrandGrayIcon}
                      alt=""
                    />
                    <h2>By Brand</h2>
                  </div>
                  {selectedProduct === 1 ? (
                    <i className="fas fa-chevron-down"></i>
                  ) : (
                    <i className="fas fa-chevron-up"></i>
                  )}
                </div>

                <div
                  className={
                    selectedProduct === 2
                      ? "contentBtnBox active"
                      : "contentBtnBox"
                  }
                  onClick={() => setSelectedProduct(2)}
                >
                  <div className="c">
                    <img
                      src={
                        selectedProduct === 2
                          ? BySegmentIcon
                          : BySegmentGrayIcon
                      }
                      alt=""
                    />
                    <h2>By Segment</h2>
                  </div>

                  {selectedProduct === 2 ? (
                    <i className="fas fa-chevron-down"></i>
                  ) : (
                    <i className="fas fa-chevron-up"></i>
                  )}
                </div>

                {/* <div
                  className={
                    selectedProduct === 3
                      ? "contentBtnBox active"
                      : "contentBtnBox"
                  }
                  onClick={() => setSelectedProduct(3)}
                >
                  <img
                    src={
                      selectedProduct === 3
                        ? ByApplicationIcon
                        : ByApplicationGrayIcon
                    }
                    alt=""
                  />
                  <h2>By Application</h2>
                  {selectedProduct === 3 ? (
                    <i className="fas fa-chevron-down"></i>
                  ) : (
                    <i className="fas fa-chevron-up"></i>
                  )}
                </div> */}
              </div>

              {selectedProduct === 1 && (
                <div className="contentBottom">
                  {byBrandData.map((item, index) => {
                    return (
                      <Link
                        to={`/products/list/1/${item.CATEGORY_ID}`}
                        // state={1}
                        key={index}
                      >
                        <div className="contentBottomBox">
                          <img src={item.IMAGE_STRING} alt="" />
                          {/* <span>
                            <div className="ml-3 discover-btn-icon transition-all duration-300 "></div>
                          </span> */}
                          <div className="contentBottomBtn">
                            <IconBtn />
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}

              {selectedProduct === 2 && (
                <div className="contentBottom">
                  {bySegmentData.map((item, index) => {
                    return (
                      <Link
                        to={`/products/list/2/${item.CATEGORY_ID}`}
                        // state={2}
                        className="SegmentBottomBox"
                        key={index}
                      >
                        <img src={item.IMAGE_STRING} alt="" />
                        <span className="SegmentBottomBoxSpan">
                          {item.CATEGORY_NAME}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              )}

              {selectedProduct === 3 && (
                <div className="contentBottom">
                  {byApplicationData.map((item, index) => {
                    return (
                      <Link
                        to={`/products/list/3/${item.CATEGORY_ID}`}
                        // state={3}
                        className="SegmentBottomBox"
                        key={index}
                      >
                        <img src={item.IMAGE_STRING} alt="" />
                        <span className="SegmentBottomBoxSpan">
                          {item.CATEGORY_NAME}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
}
