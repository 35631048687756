import React, { useEffect, useRef, useState } from "react";
import "./productDetailsPage.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

// icons
import { GrDocumentPdf } from "react-icons/gr";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  // A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// static data
import { btnData } from "./btnData";

// api imports
import { getProductsData } from "../../../apis/products.apis";

// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Footer from "../../../components/footer/Footer";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import CollapsibleComponent from "../../../components/collapsible/CollapsibleComponent";

export default function ProductDetail() {
  const navigate = useNavigate();
  SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

  // const catId = window.location.pathname.split("/")[2];
  // const pId = window.location.pathname.split("/")[3];
  const catId = useParams().productCatId;
  const pId = useParams().productId;

  const [data, setData] = useState({});
  useEffect(() => {
    getProductsData({ catId, pId })
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [catId, pId]);

  const [selectedOption, setSelectedOption] = useState("product-description");
  const Segment = [
    {
      id: 1,
      name: "Segment 1",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Concrete Additives",
    },
    {
      id: 2,
      name: "Segment 2",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Boanding and Anchoring Adhesives",
    },
    {
      id: 3,
      name: "Segment 3",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Concrete Repair Systems",
    },
    {
      id: 4,
      name: "Segment 1",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Concrete Additives",
    },
    {
      id: 5,
      name: "Segment 2",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Boanding and Anchoring Adhesives",
    },
    {
      id: 6,
      name: "Segment 3",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Concrete Repair Systems",
    },
    {
      id: 7,
      name: "Segment 1",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Concrete Additives",
    },
    {
      id: 8,
      name: "Segment 2",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Boanding and Anchoring Adhesives",
    },
    {
      id: 9,
      name: "Segment 3",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS33NUKvo6xLaAcTaCGFggAzBhYYEf7Rn2RzAVr5PB3NZ94VwKxFrAL_8KHjdXJqRbP6U0&usqp=CAU",

      desc: "Concrete Repair Systems",
    },
  ];

  //update selectedOption
  const updateSelectedOption = (option) => {
    setSelectedOption(option);
  };

  const [numImg, setNumImg] = useState(1);
  const swiperRef = useRef(null);

  const changeNumImg = () => {
    setNumImg(swiperRef.current.swiper.realIndex + 1);
  };

  return (
    <div className="productDetailsPage">
      {/* <Header /> */}
      <div className="topMask" />
      <Carousal />
      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/products" className="common__headerLinkContainer">
            <span className="linkText">Product</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div
            className="common__headerLinkContainer"
            onClick={() => navigate(-1)}
          >
            <span className="linkText">
              {data?.CATEGORY_NAME ||
                data?.BRAND_NAME ||
                data?.APPLICATION_AREA}{" "}
            </span>
            <i className="fa-solid fa-angle-right"></i>
          </div>

          <Link
            to=""
            className="common__headerLinkContainer common__headerLinkContainer__active"
          >
            <span className="linkText">Product Details</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>
        </div>
      </Wrapper>

      <div className="productDetailsContainer">
        <Wrapper>
          <div className="productDetailsContainer__block1">
            <div className="productDetailsContainerTop ">
              <h1>{data?.ITEM_NAME}</h1>

              {data.SHORT_DESCRIPTION && parse(data.SHORT_DESCRIPTION)}
            </div>

            <div className="productDetailsContainerBottom">
              <div className="prodLeft">
                <Swiper
                  className="prodImgSwiper"
                  spaceBetween={0}
                  slidesPerView={1}
                  navigation
                  ref={swiperRef}
                  loop={true}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => changeNumImg()}
                >
                  {/* {productDetails.images.map((image) => (
                  <SwiperSlide>
                    <img src={image.img} alt="" className="prodImg" />
                  </SwiperSlide>
                ))} */}
                  <SwiperSlide className="prodImg">
                    <img src={data?.ITEM_IMAGE} alt="" />
                  </SwiperSlide>
                </Swiper>
                {/* <span className="prodImgCount">
                {numImg} / {productDetails.images.length}
              </span> */}
              </div>

              <div className="prodRight">
                <div className="prodRightTop__btns">
                  {btnData.map((item, i) => {
                    return (
                      <div
                        className={
                          selectedOption === item.slug
                            ? "prodRightTop__btn active"
                            : "prodRightTop__btn"
                        }
                        onClick={() => updateSelectedOption(item.slug)}
                        key={i}
                      >
                        <div className="prodRightTop__text">
                          <span>{item.title}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="prodRightBottom">
                  {selectedOption === "product-description" ? (
                    <div className="prodRightBottomDesc">
                      {data?.LONG_DESCRIPTION && parse(data?.LONG_DESCRIPTION)}

                      <div className="aditional">
                        <div className="ad__list">
                          <span>Pack Size</span>

                          <ul>
                            <li>20kg paper bags</li>
                            <li>1000kg bulk bags</li>
                          </ul>
                        </div>

                        <div className="ad__list">
                          <span>Product Appearance</span>

                          <ul>
                            <li>Greyish Free Flowing Cement Powder</li>
                            <li>Soluble in Water</li>
                          </ul>
                        </div>

                        <div className="ad__list">
                          <span>Dosage/Coverage</span>

                          <ul>
                            <li>20Kg/M3 of Cement Weight</li>
                          </ul>
                        </div>

                        <div className="ad__list">
                          <span>Shel Life</span>

                          <ul>
                            <li>6 Months from the date of Mfg</li>
                          </ul>
                        </div>

                        <div className="ad__list">
                          <span>Color</span>

                          <ul>
                            <li>Off-White</li>
                          </ul>
                        </div>

                        <div className="ad__list">
                          <span>Density</span>

                          <ul>
                            <li> 2.25 Kg/CUM</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : selectedOption === "advantage" ? (
                    <div className="prodRightBottomDesc">
                      {data?.ADVANTAGE && parse(data?.ADVANTAGE)}
                    </div>
                  ) : selectedOption === "application-range" ? (
                    <div className="prodRightBottomDesc">
                      {data?.APPLICATION_AREA && parse(data?.APPLICATION_AREA)}
                    </div>
                  ) : selectedOption === "precaution" ? (
                    <div className="prodRightBottomDesc">
                      {data?.SEWSTIVENESS && parse(data?.SEWSTIVENESS)}
                    </div>
                  ) : selectedOption === "product-documents" ? (
                    <div className="prodRightBottomDesc">
                      <div className="prodRightBottomDescItem">
                        {data.fileList && data.fileList.length > 0
                          ? data.fileList.map((item) => {
                              return (
                                <a
                                  href={item.FilePath}
                                  target="_blank"
                                  className="prodRightBottomDescItemLink link"
                                >
                                  <GrDocumentPdf className="prodRightBottomDescItem__icon" />
                                  <span className="prodRightBottomDescItem__text">
                                    {item.FileName}
                                  </span>
                                </a>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>

      <Wrapper>
        <div className="productPage__relatedProductApplication">
          <div className="productPage__relatedProductColaps">
            <CollapsibleComponent
              // data1={data2}
              isCollapsOpen={true}
              header={"Related Products"}
            />
          </div>

          <div className="relatedProductApplication__Header">
            <h1 className="relatedProductApplication__HeaderText">
              Related Applications/Application Area
            </h1>
          </div>

          <div className="relatedProductApplication__container">
            {Segment.map((item) => {
              return (
                <div className="SegmentBottomBox">
                  <img src={item?.image} alt="" />
                  <span className="SegmentBottomBoxSpan">{item.desc}</span>
                </div>
              );
            })}
          </div>
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
}
