import React, { useEffect, useState } from "react";
import "./targetMarket.scss";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

// import Carousal from "../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../components/common/wrapper/Wrapper";
import Footer from "../../components/footer/Footer";

// api import
import { getTargetMarketData } from "../../apis/targetMarket.apis";

const TargetMarket = () => {
  const [targetMarketData, setTargetMarketData] = useState([]);
  useEffect(() => {
    getTargetMarketData()
      .then((res) => {
        setTargetMarketData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(targetMarketData);

  return (
    <div className="targetMarket">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link
            to=""
            className="common__headerLinkContainer common__headerLinkContainer__active"
          >
            <span className="linkText">Target Market</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>
        </div>

        <div className="targetMarket__container">
          {targetMarketData?.map((item, index) => {
            return (
              <div className="targetMarket__block" key={index}>
                <div className="targetMarket__block__Left">
                  <div className="targetMarket__block__LeftImage">
                    <img src={item?.IMAGE_LINK_2} alt="" />
                  </div>
                  <div className="targetMarket__block__LeftDesc">
                    <span>{item?.DESCRIPTION && parse(item.DESCRIPTION)}</span>
                  </div>
                </div>

                <div className="targetMarket__block__Right">
                  <div className="targetMarket__block__RightTitle">
                    <span>{item?.NAME}</span>
                  </div>
                  <div className="targetMarket__block__RightImage">
                    <img src={item?.IMAGE_LINK_2} alt="" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default TargetMarket;
