import React, { useEffect, useState } from "react";
import "./landingExploreProduct.scss";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination, Navigation } from "swiper";

import Wrapper from "../../../components/common/wrapper/Wrapper";
import Wrapper2 from "../../../components/common/wrapper2/Wrapper2";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";
import IconBtn from "../../../components/common/buttons/iconBtn/IconBtn";

const LandingExploreProduct = ({ productData }) => {
  const [exploreOurProducts, setExploreOurProducts] =
    useState("Concrete Additive");
  const [catId, setCatId] = useState(0);
  const [linkId, setLinkId] = useState(1);
  const handleExploreOurProducts = (value, cat, passId) => {
    setExploreOurProducts(value);
    setCatId(cat);
    setLinkId(passId);
  };

  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleShiftLeft = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const handleShiftRight = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        if (swiper.isBeginning) {
          setIsBeginning(true);
          setIsEnd(false);
        } else if (swiper.isEnd) {
          setIsBeginning(false);
          setIsEnd(true);
        } else {
          setIsBeginning(false);
          setIsEnd(false);
        }
      });
    }
  }, [swiper]);

  const [swiper1, setSwiper1] = useState(null);
  const [isBeginning1, setIsBeginning1] = useState(true);
  const [isEnd1, setIsEnd1] = useState(false);
  const handleShiftLeft1 = () => {
    if (swiper1) {
      swiper1.slidePrev();
    }
  };
  const handleShiftRight1 = () => {
    if (swiper1) {
      swiper1.slideNext();
    }
  };
  useEffect(() => {
    if (swiper1) {
      swiper1.on("slideChange", () => {
        if (swiper1.isBeginning1) {
          setIsBeginning1(true);
          setIsEnd1(false);
        } else if (swiper1.isEnd1) {
          setIsBeginning1(false);
          setIsEnd1(true);
        } else {
          setIsBeginning1(false);
          setIsEnd1(false);
        }
      });
    }
  }, [swiper1]);

  return (
    <div className="landingExploreProduct">
      <Wrapper>
        <div className="landingExploreProductTitleAndIcon">
          <span className="landingExploreProduct__title">Explore Product</span>
          {/* <img
            src={require("../../../assets/images/home/About Underline.png")}
            alt=""
          /> */}
        </div>

        <div className="landingPageProductFilter__container">
          <i
            className={
              isBeginning === true
                ? "fa-solid fa-chevron-left iconDisabled"
                : "fa-solid fa-chevron-left"
            }
            onClick={handleShiftLeft}
          />

          <Swiper
            className="landingPageProductFilter__filterItemContainer"
            slidesPerView={3}
            onSwiper={setSwiper}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {productData?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className={
                      exploreOurProducts === item.CATEGORY_NAME
                        ? "landingPageProductFilter__filterItem activeItem"
                        : "landingPageProductFilter__filterItem"
                    }
                    onClick={() =>
                      handleExploreOurProducts(
                        item.CATEGORY_NAME,
                        index,
                        item.CATEGORY_ID
                      )
                    }
                  >
                    <div className="landingPageProductFilter__filterItem__line"></div>
                    <div className="landingPageProductFilter__filterItem__text">
                      <button>{item.CATEGORY_NAME}</button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <i
            className={
              isEnd === true
                ? "fa-solid fa-chevron-right iconDisabled"
                : "fa-solid fa-chevron-right"
            }
            onClick={handleShiftRight}
          />
        </div>
      </Wrapper>

      <Wrapper2>
        <Swiper
          className="landingExploreProduct__container"
          slidesPerView={3}
          spaceBetween={30}
          onSwiper={setSwiper1}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            850: {
              slidesPerView: 2,
            },
            1190: {
              slidesPerView: 3,
            },
          }}
        >
          {productData[catId]?.productList?.map((slide, index) => {
            return (
              <SwiperSlide
                className="landingExploreProduct__containerItem"
                key={index}
              >
                <div className="lep__itemImg">
                  <img src={slide?.ITEM_IMAGE} alt="" />
                </div>

                <div className="lep__itemContent">
                  <div className="lep__itemContentTop">
                    <span className="lep__itemContentTitle">
                      {slide.ITEM_NAME}
                    </span>
                    <span className="lep__itemContentDesc">
                      {slide.SHORT_DESCRIPTION &&
                        parse(
                          `<span style="color: white;">${slide.SHORT_DESCRIPTION}</span>`
                        )}
                    </span>
                  </div>

                  <div className="lep__itemContentBtn">
                    <Link
                      to={`/products/${slide.CATEGORY_ID}/${slide.ITEM_ID}`}
                      className="link"
                    >
                      <IconBtn />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Wrapper2>

      <Wrapper>
        <div className="landingExploreProduct__bottom">
          <div className="landingExploreProduct__bottomLeft">
            <img
              src={require("../../../assets/images/common_icons/left right.png")}
              alt=""
              className={
                isBeginning1 === true
                  ? "landingExploreProductBtn deactiveBtn"
                  : "landingExploreProductBtn"
              }
              onClick={handleShiftLeft1}
            />

            <img
              src={require("../../../assets/images/common_icons/left right.png")}
              alt=""
              className={
                isEnd1 === true
                  ? "landingExploreProductBtn deactiveBtn"
                  : "landingExploreProductBtn"
              }
              onClick={handleShiftRight1}
            />
          </div>

          <div className="landingExploreProduct__bottomRight">
            <Link to={`/products/${linkId}`} state={2} className="link">
              <TextIconBtn name="Explore All" />
            </Link>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingExploreProduct;
