import axios from "axios";

import {
  LANDING_PAGE_PRODUCT_URL,
  PRODUCT_URL,
  PRODUCT_TYPE_URL,
} from "../utils/urls";

export const getLandingPageProductData = () => {
  return axios({
    method: "GET",
    url: LANDING_PAGE_PRODUCT_URL,
  });
};

export const getProductPageData = (id) => {
  return axios({
    method: "GET",
    url: PRODUCT_TYPE_URL + "/GetProductType",
  });
};

export const getProductsTypeData = () => {
  return axios({
    method: "GET",
    url: PRODUCT_URL,
  });
};

export const getProductsCatData = ({ stateId, productCatId }) => {
  return axios({
    method: "GET",
    // url: PRODUCT_TYPE_URL + "?options=" + brandId + "&catId=" + productCatId,
    url:
      PRODUCT_TYPE_URL +
      "/GetProductByOptions" +
      "/" +
      stateId +
      "/" +
      productCatId,
  });
};

export const getProductsData = ({ catId, pId }) => {
  // console.log("catId", catId);
  // console.log("pId", pId);
  return axios({
    method: "GET",
    // url: PRODUCT_TYPE_URL + "?catId=" + catId + "&pId=" + pId,
    url:
      PRODUCT_TYPE_URL + "/GetProductByCatIdAndPId" + "/" + catId + "/" + pId,
  });
};
