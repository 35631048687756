import React from "react";
import "./carousal.scss";

import { useEffect, useRef, useState } from "react";

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  // A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// import CarItemViewer from "./carousalItem/CarItemViewer";

import yollowImage from "../../../assets/images/icons/Product Slider hober sign 02.png";
import blackImgae from "../../../assets/images/icons/Product Slider sign 01.png";

import carouselItems from "./data";

export default function Carousal({ progressSlide, single }) {
  const [autoplay, setAutoPlay] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  // console.log("currentSlide", currentSlide);

  SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);
  const swiperRef = useRef(null);

  // console.log("swiperRef", swiperRef.current?.swiper?.realIndex);

  const pauseOrPlay = () => {
    if (autoplay) {
      swiperRef.current.swiper.autoplay.stop();
      setAutoPlay(false);
    } else {
      swiperRef.current.swiper.autoplay.start();
      setAutoPlay(true);
    }
  };

  const updateCurrentSlide = () => {
    setCurrentSlide(swiperRef.current.swiper.realIndex);
  };

  useEffect(() => {
    if (single) {
      swiperRef.current.swiper.autoplay.stop();
      setAutoPlay(false);
    }

    if (progressSlide) {
      swiperRef.current.swiper.autoplay.start();
      setAutoPlay(true);
    }
  }, [single, currentSlide, progressSlide]);

  // add carousel__textHeader animation class when slide change
  useEffect(() => {
    const carousel__textHeader = document.querySelector(
      ".carousel__textHeader"
    );
    const carousel__textDesc = document.querySelector(".carousel__textDesc");
    carousel__textHeader.classList.add("animate__animated");
    carousel__textDesc.classList.add("animate__animated");
    setTimeout(() => {
      carousel__textHeader.classList.remove("animate__animated");
      carousel__textDesc.classList.remove("animate__animated");
    }, 2000);
  }, [currentSlide]);

  return (
    <div className="carousal_test">
      <Swiper
        className="cariusel__swiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        ref={swiperRef}
        onSlideChange={(swiper) => {
          updateCurrentSlide(swiperRef.current.swiper.realIndex);
        }}
        onSwiper={(swiper) => {
          setTotalSlides(swiper.slides.length);
        }}
      >
        {carouselItems.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={item.image} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="carousel__dummy">
        {progressSlide && (
          <div className="side_index">
            <div className="current_slide"> 0{currentSlide + 1}</div>

            {Array.from(Array(totalSlides).keys()).map((item, index) => (
              <div
                className="side_index_item"
                onClick={() => {
                  swiperRef.current.swiper.slideTo(item);
                }}
                key={index}
              >
                <img
                  src={item === currentSlide ? yollowImage : blackImgae}
                  alt="side index"
                />
              </div>
            ))}

            {autoplay ? (
              <div
                className="play_pause"
                onClick={() => {
                  pauseOrPlay();
                }}
              >
                <i className="fa-solid fa-pause"></i>
              </div>
            ) : (
              <div
                className="play_pause"
                onClick={() => {
                  pauseOrPlay();
                }}
              >
                <i className="fa-solid fa-play"></i>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="carousel__textContainer">
        <div className="carousel__text">
          <div className="carousel__textDiv">
            {/* <Swiper
              className="cariusel__swipertext"
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop={true}
            >
              {carouselItems.map((item, index) => {
                return (
                  <SwiperSlide className="cs">
                    <span className="carousel__textHeader">{item.title}</span>

                    <span className="carousel__textDesc">{item.desc}</span>
                  </SwiperSlide>
                );
              })}
            </Swiper> */}

            {/* <div className="carousel__textHeader">
              {carouselItems[currentSlide]?.title}
            </div> */}

            <div className="carousel__textHeader animate__animated">
              {carouselItems[currentSlide]?.title}
            </div>

            <div className="carousel__textDesc animate__animated">
              {carouselItems[currentSlide]?.desc}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
