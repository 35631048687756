import React, { useEffect, useState } from "react";
import "../openPositionSingleList/openPositionList.scss";

import { Link } from "react-router-dom";

// compinet import
import Footer from "../../../components/footer/Footer";
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";

// api import
import { getWorkWithUsData } from "../../../apis/workWithUs.apis";

const WorkWithUsAllList = () => {
  const [workWithUsDeptData, setWorkWithUsDeptData] = useState([]);
  useEffect(() => {
    getWorkWithUsData()
      .then((res) => {
        setWorkWithUsDeptData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("workWithUsDeptData", workWithUsDeptData);

  return (
    <div className="openPositions">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/workWithUs" className="common__headerLinkContainer ">
            <span className="linkText">Work With Us</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">All Position List</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="openPositions__container">
          <div className="openPositions__headerDesc">
            <span className="openPositions__headerText">Positions</span>
            <div className="openPositions__headerText__underline"></div>
            <span className="openPositions__descText">
              {/* {workWithUsDeptData.JOB_SUMMERY &&
                parse(workWithUsDeptData.JOB_SUMMERY)} */}
              TOCOMA is an international player with strong human values.
              Working at TOCOMA is stimulating and rewarding. Your talent
              associated to our ambitions will provide you great development
              opportunities.
            </span>
          </div>

          {workWithUsDeptData.map((dept) => {
            return (
              <div className="positions__container">
                <div className="positions__header">
                  <span className="positions__headerText">
                    {dept.DEPARTMENT_NAME}
                  </span>
                  <div className="positions__headerText__underline"></div>
                </div>

                <div className="positions__rowContainer">
                  {dept?.positionList?.map((pos) => {
                    return (
                      <Link
                        to={`/workWithUs/${pos.DEPARTMENT_ID}/${pos.JOB_DESCRIPTION_ID}`}
                        // to=""
                        className="positions__row"
                      >
                        <div className="positions__rowLeft">
                          <span>{pos.POSITION}</span>
                        </div>
                        <div className="positions__rowRight">
                          <span>{pos.JOB_LOCATION}</span>
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default WorkWithUsAllList;
