import React, { useEffect, useState } from "react";
import "./openPositionList.scss";

import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import positionsData from "./positionsData";

// components import
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import Footer from "../../../components/footer/Footer";

// api import
import { getWorkWithUsDataByDeptId } from "../../../apis/workWithUs.apis";

const OpenPositionList = () => {
  const { workDeptId } = useParams();

  const [workWithUsDeptData, setWorkWithUsDeptData] = useState([]);
  useEffect(() => {
    getWorkWithUsDataByDeptId(workDeptId)
      .then((res) => {
        setWorkWithUsDeptData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [workDeptId]);
  console.log("workWithUsDeptData", workWithUsDeptData);

  return (
    <div className="openPositions">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/workWithUs" className="common__headerLinkContainer ">
            <span className="linkText">Work With Us</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Position List</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="openPositions__container">
          <div className="openPositions__headerDesc">
            <span className="openPositions__headerText">Positions</span>
            <div className="openPositions__headerText__underline"></div>
            <span className="openPositions__descText">
              {workWithUsDeptData.JOB_SUMMERY &&
                parse(workWithUsDeptData.JOB_SUMMERY)}
            </span>
          </div>

          {/* {workWithUsDeptData.map((dept) => {
            return ( */}
          <div className="positions__container">
            <div className="positions__header">
              <span className="positions__headerText">
                {workWithUsDeptData.DEPARTMENT_NAME}
              </span>
              <div className="positions__headerText__underline"></div>
            </div>

            <div className="positions__rowContainer">
              {workWithUsDeptData?.positionList?.map((pos) => {
                return (
                  <Link
                    to={`/workWithUs/${workDeptId}/${pos.JOB_DESCRIPTION_ID}`}
                    className="positions__row"
                  >
                    <div className="positions__rowLeft">
                      <span>{pos.POSITION}</span>
                    </div>
                    <div className="positions__rowRight">
                      <span>{pos.JOB_LOCATION}</span>
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          {/* );
          })} */}

          {/* {positionsData.map((position) => {
            return (
              <div className="positions__container">
                <div className="positions__header">
                  <span className="positions__headerText">
                    {position.title}
                  </span>
                  <div className="positions__headerText__underline"></div>
                </div>

                <div className="positions__rowContainer">
                  {position.positions.map((pos) => {
                    return (
                      <Link to={pos.link} className="positions__row">
                        <div className="positions__rowLeft">
                          <span>{pos.positionName}</span>
                        </div>
                        <div className="positions__rowRight">
                          <span>{pos.location}</span>
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })} */}
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default OpenPositionList;
