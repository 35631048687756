import React, { useState } from "react";
import "./collapsibleComponent.scss";
import Collapsible from "react-collapsible";

import parser from "html-react-parser";
import { Link } from "react-router-dom";
import TextIconBtn from "../common/buttons/textIconBtn/TextIconBtn";
import TextIconBtnSmall from "../common/buttons/textIconBtnSmall/TextIconBtnSmall";

export default function CollapsibleComponent({
  data1,
  productListData,
  id,
  isCollapsOpen,
  header,
  isSegment,
}) {
  const [isOpen, setIsOpen] = useState(isCollapsOpen || false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // console.log("segment Data", data1);
  // console.log("p List Data", productListData);

  return (
    <div className="collapsible">
      <Collapsible
        className="collapsible__container"
        open={isOpen}
        trigger={
          <div
            className="flex justify-between items-center"
            onClick={handleToggle}
          >
            <h1>
              {data1?.SUBCATEGORY_NAME ||
                data1?.CATEGORY_NAME ||
                data1?.BRAND_NAME ||
                header}

              {productListData?.SUBCATEGORY_NAME ||
                productListData?.CATEGORY_NAME ||
                productListData?.BRAND_NAME ||
                header}
            </h1>
            <span>
              {isOpen ? (
                <i className="fas fa-chevron-up"> </i>
              ) : (
                <i className="fas fa-chevron-down"> </i>
              )}
            </span>
          </div>
        }
      >
        <div className="gridContainer">
          {productListData?.productList &&
            productListData?.productList?.map((col, i) => {
              return (
                <Link
                  to={`/products/${col.CATEGORY_ID}/${col?.ITEM_ID}`}
                  // state={{ data: col }}
                  className="gridItem link"
                  key={i}
                >
                  <div className="gridImg">
                    <img src={col?.ITEM_IMAGE} alt="" />
                  </div>

                  <div className="gridContent">
                    <span className="catName">{col?.ITEM_NAME}</span>
                    <div>{parser(col?.SHORT_DESCRIPTION)}</div>
                    <span className="spanTxt">
                      Made By {col?.BRAND_NAME} {col?.MAID_IN_COUNTRY}
                    </span>

                    <span className="gridBtnHolder">
                      <TextIconBtnSmall name={"View Details"} />
                    </span>
                  </div>
                </Link>
              );
            })}

          {data1?.productList &&
            data1?.productList?.map((col, i) => {
              return (
                <Link
                  to={`/products/${id}/${col?.ITEM_ID}`}
                  className="gridItem"
                  key={i}
                >
                  <div className="gridImg">
                    <img src={col?.ITEM_IMAGE} alt="" />
                  </div>

                  <div className="gridContent">
                    <span className="catName">{col?.ITEM_NAME}</span>
                    <div>{parser(col?.SHORT_DESCRIPTION)}</div>
                    <span className="spanTxt">
                      Made By {col?.BRAND_NAME} {col?.MAID_IN_COUNTRY}
                    </span>

                    <span className="gridBtnHolder">
                      <TextIconBtnSmall name={"View Details"} />
                    </span>
                  </div>
                </Link>
              );
            })}
        </div>
      </Collapsible>
    </div>
  );
}
