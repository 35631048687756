import React, { useEffect, useState } from "react";
import "./partnerDetailPage.scss";
import { Link, useParams } from "react-router-dom";

import parse from "html-react-parser";

// comonents import
import Footer from "../../../components/footer/Footer";
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";

// dummy data import
// import { categoriesData1 } from "./data";
// import { categoriesData2 } from "./data";

import { getOurPartnerDetailsData } from "../../../apis/ourPartners.apis";
import Wrapper from "../../../components/common/wrapper/Wrapper";

export default function PartnerDetailPage() {
  const { partnerId } = useParams();

  // api call
  const [partnerDetailData, setPartnerDetailData] = useState([]);
  useEffect(() => {
    getOurPartnerDetailsData({ id: partnerId })
      .then((res) => {
        setPartnerDetailData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [partnerId]);
  console.log("partnerDetailData1", partnerDetailData);

  return (
    <div>
      {/* <Header /> */}
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/partners" className="common__headerLinkContainer">
            <span className="linkText">Our Partners</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link
            to=""
            className="common__headerLinkContainer common__headerLinkContainer__active"
          >
            <span className="linkText">Partner Details</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>
        </div>

        <div className="partnerDetailPage">
          <div className="partnerDetailPage__container">
            <div className="partnerLogo__container">
              <img src={partnerDetailData?.IMAGE_LINK} alt="" />
              {/* <img
                // src={{`https://www.tocoma.co/api/Ourpartners/OurPartnersImg/${pId}`}}
                // src={`https://www.tocoma.co/api/Ourpartners/OurPartnersImg/${partnerDetailData?.IMAGE_LINK}`}
                src=""
                alt=""
              /> */}
            </div>

            <div className="partnerDetailPage__headerDesc__container">
              <div className="partnerDetailPage__header">
                {partnerDetailData?.NAME && (
                  <span className="partnerDetailPage__header__text">
                    {partnerDetailData?.NAME}
                  </span>
                )}

                <span className="partnerDetailPage__desc__Text">
                  {partnerDetailData.DESCRIPTION &&
                    parse(partnerDetailData?.DESCRIPTION)}
                </span>
              </div>
            </div>

            <div className="partnerDetailPage__businessCart__container">
              <div className="partnerDetailPage__businessCart__header">
                <span className="partnerDetailPage__businessCart__header__text">
                  Business Categories
                </span>
              </div>

              <div className="partnerDetailPage__businessCart__cardContainer">
                {partnerDetailData?.partnersBusinessList?.map((item, index) => {
                  return (
                    <div
                      className="partnerDetailPage__businessCart__card"
                      key={index}
                    >
                      <div className="partnerDetailPage__businessCart__card__image">
                        <img src={item.IMAGE_LINK} alt="" />
                      </div>
                      <div className="partnerDetailPage__businessCart__card__text">
                        <span>{item.IMAGE_TITLE}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
}
