import React from "react";
import "./landingPage.scss";

import { useEffect, useRef, useState } from "react";

// component impoet
import Footer from "../../components/footer/Footer";
// import Carousal from "../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";
// import FactAndFigures from "../../components/commonPageComponent/factAndFigures/FactAndFigures";

import HomeAbout from "./langingAbout/LandingAbout";
import LandingExploreProduct from "./landingExploreProduct/LandingExploreProduct";
import LandingTargetMarket from "./landingTargetMarket1/LandingTargetMarket";

// Api imports
import { getReferenceProjectLandingPageData } from "../../apis/reference.apis";
import { getOurPartnerData } from "../../apis/ourPartners.apis";
import { getNewsAndEventsData } from "../../apis/newsAndEvents.apis";
import { getAboutData } from "../../apis/aboutTocoma.apis";
import { getTargetMarketData } from "../../apis/targetMarket.apis";
import { getLandingPageProductData } from "../../apis/products.apis";
import LandingReferenceProject from "./landingReferenceProject/LandingReferenceProject";
import LandingPartners from "./landingPartners/LandingPartners";
// import LandingNews from "./landingNews/LandingNews";
import LandingCareer from "./landingCareer/LandingCareer";

function LandingPage2() {
  const [currentSlide, setCurrentSlide] = useState(null);

  const myContainerRef = useRef(null);
  const myElementRef1 = useRef([null]);
  const myElementRef2 = useRef([null]);
  const myElementRef3 = useRef([null]);
  const myElementRef4 = useRef([null]);
  const myElementRef5 = useRef([null]);
  const myElementRef6 = useRef([null]);
  const myElementRef7 = useRef([null]);
  const myElementRef8 = useRef([null]);
  const myElementRef9 = useRef([null]);
  const myElementRef10 = useRef([null]);

  const [refScrollPosition, setRefScrollPosition] = useState(0);
  const handleScrollLeft = () => {
    setRefScrollPosition(myContainerRef.current.scrollTop);
    // console.log(refScrollPosition);
  };

  const goToNextRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleSetCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  //use Effect
  useEffect(() => {
    if (refScrollPosition < 800) {
      if (currentSlide) {
        handleSetCurrentSlide(0);
      }
    } else if (refScrollPosition > 800 && refScrollPosition < 1800) {
      handleSetCurrentSlide(1);
    } else if (refScrollPosition > 1800 && refScrollPosition < 2600) {
      handleSetCurrentSlide(2);
    } else if (refScrollPosition > 2600 && refScrollPosition < 3400) {
      handleSetCurrentSlide(3);
    } else if (refScrollPosition > 3400 && refScrollPosition < 4200) {
      handleSetCurrentSlide(4);
    } else if (refScrollPosition > 4200 && refScrollPosition < 5000) {
      handleSetCurrentSlide(5);
    } else if (refScrollPosition > 5000 && refScrollPosition < 5800) {
      handleSetCurrentSlide(6);
      // } else if (refScrollPosition > 5800 && refScrollPosition < 6600) {
      //   handleSetCurrentSlide(7);
      // } else if (refScrollPosition > 6600 && refScrollPosition < 7600) {
      //   handleSetCurrentSlide(8);
    } else {
      handleSetCurrentSlide(9);
    }

    // eslint-disable-next-line
  }, [
    refScrollPosition,
    currentSlide,
    myElementRef1,
    myElementRef2,
    myElementRef3,
    myElementRef4,
    myElementRef5,
    myElementRef6,
    myElementRef7,
    myElementRef8,
    myElementRef9,
    myElementRef10,
    myContainerRef,
    handleSetCurrentSlide,
  ]);

  // useEffect(() => {
  //   if (refScrollPosition < 800) {
  //     if (currentSlide) {
  //       handleSetCurrentSlide(0);
  //     }
  //   } else if (refScrollPosition > 800 && refScrollPosition < 1800) {
  //     handleSetCurrentSlide(1);
  //   } else if (refScrollPosition > 1800 && refScrollPosition < 2600) {
  //     handleSetCurrentSlide(2);
  //   } else if (refScrollPosition > 2600 && refScrollPosition < 3400) {
  //     handleSetCurrentSlide(3);
  //   } else if (refScrollPosition > 3400 && refScrollPosition < 4200) {
  //     handleSetCurrentSlide(4);
  //   } else if (refScrollPosition > 4200 && refScrollPosition < 5000) {
  //     handleSetCurrentSlide(5);
  //   } else if (refScrollPosition > 5000 && refScrollPosition < 5800) {
  //     handleSetCurrentSlide(6);
  //   } else if (refScrollPosition > 5800 && refScrollPosition < 6600) {
  //     handleSetCurrentSlide(7);
  //   } else if (refScrollPosition > 6600 && refScrollPosition < 7600) {
  //     handleSetCurrentSlide(8);
  //   } else {
  //     handleSetCurrentSlide(9);
  //   }
  // }, [
  //   refScrollPosition,
  //   currentSlide,
  //   myElementRef1,
  //   myElementRef2,
  //   myElementRef3,
  //   myElementRef4,
  //   myElementRef5,
  //   myElementRef6,
  //   myElementRef7,
  //   myElementRef8,
  //   myElementRef9,
  //   myElementRef10,
  //   myContainerRef,
  //   handleSetCurrentSlide,
  // ]);

  // Api call for news and events

  const [aboutTocomaData, setAboutTocomaData] = useState([]);
  useEffect(() => {
    getAboutData()
      .then((res) => {
        setAboutTocomaData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("aboutTocomaData", aboutTocomaData);

  // Api call for reference products
  const [referenceProjects, setReferenceProjects] = useState([]);
  useEffect(() => {
    getReferenceProjectLandingPageData()
      .then((res) => {
        setReferenceProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("referenceProducts", referenceProjects);

  // Api call for partners Data
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    getOurPartnerData()
      .then((res) => {
        setPartners(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("partners", partners);

  // Api call for news and events
  const [newsAndEvents, setNewsAndEvents] = useState([]);
  useEffect(() => {
    getNewsAndEventsData()
      .then((res) => {
        setNewsAndEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("newsAndEvents", newsAndEvents);

  // Api call for target market
  const [targetMarketData, setTargetMarketData] = useState([]);
  useEffect(() => {
    getTargetMarketData()
      .then((res) => {
        setTargetMarketData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("targetMarketData", targetMarketData);

  // Api call for product
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    getLandingPageProductData()
      .then((res) => {
        setProductData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("productData", productData);

  return (
    <div className="landing_screen_container_full">
      <div
        ref={myContainerRef}
        className="landing_screen_container"
        onScroll={handleScrollLeft}
      >
        <div className="section1" ref={myElementRef1}>
          <div className="topMask" />

          <Carousal progressSlide={true} />
        </div>

        <div className="section2" ref={myElementRef2}>
          <HomeAbout data={aboutTocomaData} />
        </div>

        <div className="section3" ref={myElementRef3}>
          <LandingTargetMarket data={targetMarketData} />
        </div>

        <div className="section4" ref={myElementRef4}>
          <LandingExploreProduct productData={productData} />
        </div>

        <div className="section5" ref={myElementRef5}>
          <LandingReferenceProject referenceProjects={referenceProjects} />
        </div>

        {/* <div className="section5" ref={myElementRef6}>
          <div className="s_cont">
            <FactAndFigures />
          </div>
        </div> */}

        <div className="section6" ref={myElementRef7}>
          <LandingPartners partners={partners} />
        </div>

        {/* <div className="section7" ref={myElementRef8}>
          <LandingNews newsAndEvents={newsAndEvents} />
        </div> */}

        <div className="section7" ref={myElementRef9}>
          <LandingCareer />
        </div>

        <div className="sectionFooter" ref={myElementRef10}>
          <Footer />
        </div>
      </div>

      <div className="right_navigation">
        <div className="right_navigation_container">
          <div
            className={
              currentSlide === 0
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 0
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef1);
              }}
            >
              Top
            </div>
          </div>

          <div
            className={
              currentSlide === 1
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            {" "}
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 1
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef2);
              }}
            >
              About
            </div>
          </div>

          <div
            className={
              currentSlide === 2
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            {" "}
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 2
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef3);
              }}
            >
              Target Market
            </div>
          </div>

          <div
            className={
              currentSlide === 3
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            {" "}
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 3
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef4);
              }}
            >
              Explore
            </div>
          </div>

          <div
            className={
              currentSlide === 4
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            {" "}
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 4
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef5);
              }}
            >
              Projects
            </div>
          </div>

          {/* <div
            className={
              currentSlide === 5
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            {" "}
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 5
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef6);
              }}
            >
              Facts & Figures
            </div>
          </div> */}

          <div
            className={
              currentSlide === 5
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            {" "}
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 5
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef7);
              }}
            >
              Our Partners
            </div>
          </div>

          {/* <div
            className={
              currentSlide === 7
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 7
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef8);
              }}
            >
              News & Events
            </div>
          </div> */}

          <div
            className={
              currentSlide === 6
                ? "right_navigation_container_holder yollowLeft"
                : "right_navigation_container_holder"
            }
          >
            <div
              className={
                currentSlide === null
                  ? "right_navigation_item "
                  : currentSlide === 6
                  ? "right_navigation_item active"
                  : "right_navigation_item inactive"
              }
              onClick={() => {
                goToNextRef(myElementRef9);
              }}
            >
              Career
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage2;
