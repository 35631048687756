import React from "react";
import "./contactUs.scss";

import { Link } from "react-router-dom";

// component imports
// import Carousal from "../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../components/common/wrapper/Wrapper";
import Footer from "../../components/footer/Footer";

import branchesData from "./branchData";

const ContactUs = () => {
  return (
    <div className="contactUs">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Contact Us</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="contactUs__container">
          <div className="contactUs__mapAnddetails">
            <div className="contactUs__map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d456.49078291652165!2d90.41540483323615!3d23.750008731502252!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b99897e2ffad%3A0x8530c175fcfaf178!2sTOCOMA%20Limited!5e0!3m2!1sen!2sbd!4v1688448335315!5m2!1sen!2sbd"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
            </div>

            <div className="contactUs__details">
              <div className="contactUs__detailsContainer__headerAndDetails">
                <span className="contactUs__detailsContainer__headerText">
                  Headquarters
                </span>

                <span className="contactUs__detailsContainer__detailsText">
                  1005 Malibagh Chowdhurypara, Khilgaon, Dhaka-1219, Bangladesh.
                </span>
              </div>

              <div className="contactUs__detailsContainer__headerAndDetails">
                <span className="contactUs__detailsContainer__headerText">
                  Phone
                </span>

                <span className="contactUs__detailsContainer__detailsText">
                  +88 01812120000, +88 0176 7456459
                </span>
              </div>

              <div className="contactUs__detailsContainer__headerAndDetails">
                <span className="contactUs__detailsContainer__headerText">
                  Email
                </span>

                <span className="contactUs__detailsContainer__detailsText">
                  info@tocoma.com.bd
                </span>
              </div>

              <div className="contactUs__detailsContainer__headerAndDetails">
                <span className="contactUs__detailsContainer__headerText">
                  Web Url
                </span>

                <span className="contactUs__detailsContainer__detailsText">
                  www.tocoma.com.bd
                </span>
              </div>

              <button className="contactUs__detailsContainerBtn">
                Find us on Google Maps{" "}
                <img
                  src={require("../../assets/images/contact/Find Button arrow.png")}
                  alt="map arrow"
                />
              </button>
            </div>
          </div>

          <div className="contactUs__branchesContainer">
            <div className="contactUs__branchesContainer__header">
              <span className="contactUs__branchesContainer__headerText">
                The Branches
              </span>
            </div>

            <div className="contactUs__branchesList">
              {branchesData.map((item, index) => {
                return (
                  <div className="contactUs__branchesList__item">
                    <div className="contactUs__branchesList__itemContainer">
                      <span className="contactUs__branchesList__item__header">
                        Address
                      </span>
                      <span className="contactUs__branchesList__item__details">
                        {item.address}
                      </span>
                    </div>

                    <div className="contactUs__branchesList__itemContainer">
                      <span className="contactUs__branchesList__item__header">
                        Phone
                      </span>
                      <span className="contactUs__branchesList__item__details">
                        {item.phone}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="contactUs__contactAndForm">
            <div className="contactUs__contactHeader">
              <span className="contactUs__contactHeader__text">Contact</span>
            </div>

            <div className="contactUs__contactDesc">
              <span className="contactUs__contactUs__contactDesc__text">
                Do you have a message for us? Questions on products or
                processes? Would you like us to call you? Please use the contact
                form below for your inquiry.
              </span>
            </div>

            <div className="contactUs__contactForm">
              <div className="contactUs__threeM__inputContainer">
                <select name="Title" id="" className="contactUs__select">
                  <option value="">Mr.</option>
                  <option value="">Mrs.</option>
                  <option value="">Miss.</option>
                </select>

                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Name"
                />

                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Contact"
                />
              </div>

              <div className="contactUs__threeE__inputContainer">
                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Email"
                />

                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Address"
                />

                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="City"
                />
              </div>

              <div className="contactUs__threeL__inputContainer">
                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Zip Code"
                />

                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Province"
                />

                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Cpmpany"
                />
              </div>

              <div className="contactUs__threeM__inputContainer">
                <select name="" id="" className="contactUs__select">
                  <option value="">Country</option>
                  <option value="">Bangladesh</option>
                  <option value="">India</option>
                  <option value="">Pakistan</option>
                  <option value="">Nepal</option>
                </select>

                <select name="" id="" className="contactUs__select">
                  <option value="">Type of Business or Profession</option>
                </select>

                <input
                  type="text"
                  className="contactUs__threeM__input"
                  placeholder="Area Of Interest"
                />
              </div>

              <div className="contactUs__two__inputContainer">
                <select name="" id="" className="contactUs__select">
                  <option value="">Request For</option>
                  <option value="">Request For</option>
                  <option value="">Request For</option>
                </select>
                <select name="" id="" className="contactUs__select">
                  <option value="">Select Product</option>
                  <option value="">Select Product</option>
                  <option value="">Select Product</option>
                  <option value="">Select Product</option>
                </select>
              </div>

              <div className="contactUs__two__inputContainer">
                <select name="" id="" className="contactUs__select">
                  <option value="">Area Of Interest</option>
                  <option value="">Request For</option>
                  <option value="">Request For</option>
                </select>
                <select name="" id="" className="contactUs__select">
                  <option value="">How did you hear about us?</option>
                  <option value="">Select Product</option>
                  <option value="">Select Product</option>
                  <option value="">Select Product</option>
                </select>
              </div>

              <div className="contactUs__messageBox">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="contactUs__textarea"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="contactUs__btnContainer">
            <div className="contactUs__checkBoxContainer">
              <span className="contactUs__checkBoxContainer__headerText">
                We will all you back on request
              </span>

              <div className="checkboxContainer">
                <input type="checkbox" className="contactUs__checkbox" />
                <label for="data_protection"> Please call me</label>
              </div>
            </div>

            <div className="contactUs__checkBoxContainer">
              <span className="contactUs__checkBoxContainer__headerText">
                Data protection
              </span>

              <div className="checkboxContainer">
                <input type="checkbox" className="contactUs__checkbox" />
                <label for="data_protection"> I'm agree</label>
              </div>
            </div>

            <button className="contactUs__subimtBtn primary__yellowBtn">
              Submit
            </button>
          </div>
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default ContactUs;
