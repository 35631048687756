import axios from "axios";

import { TARGET_MARKET_URL } from "../utils/urls";

export const getTargetMarketData = () => {
  return axios({
    method: "GET",
    url: TARGET_MARKET_URL,
  });
};
