import React, { useEffect, useState } from "react";
import "./referenceProject.scss";

import { Link } from "react-router-dom";

// component import
// import Carousal from "../../../components//commonPageComponent/carousel/Carousal";
import Carousal from "../../../components//commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import Footer from "../../../components/footer/Footer";

import { getReferenceProjectData } from "../../../apis/reference.apis";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";

const ReferenceProject = () => {
  const [referenceProjectData, setReferenceProjectData] = useState([]);
  useEffect(() => {
    getReferenceProjectData()
      .then((res) => {
        setReferenceProjectData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(referenceProjectData);

  return (
    <div className="referenceProject">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Reference Project</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="referenceProject__container">
          <div className="referenceProject__Block">
            {referenceProjectData?.map((item, index) => {
              return (
                <div className="referenceProdect__card" key={index}>
                  <div className="referenceProdect__card__image">
                    <img
                      src={item?.REF_PROJECT_TYPE_IMAGE}
                      alt="referenceProject"
                    />
                  </div>

                  <div className="referenceProdect__card__content">
                    <div className="referenceProdect__card__content__title">
                      <span>{item?.REF_PROJECT_TYPE_NAME}</span>
                      <div className="referenceProdect__card__contentUnderline"></div>
                    </div>

                    <div className="referenceProdect__card__content__button">
                      <Link
                        to={`/reference/${item?.REF_PROJECT_TYPE_ID}`}
                        className="referenceProdect__card__content__button__link link"
                      >
                        <TextIconBtn name="Discover" white={true} />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default ReferenceProject;
