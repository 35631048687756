import React from "react";
import "./landingTargetMarket.scss";

import Wrapper from "../../../components/common/wrapper/Wrapper";
import Wrapper2 from "../../../components/common/wrapper2/Wrapper2";
import { Link } from "react-router-dom";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";

const LandingTargetMarket = ({ data }) => {
  return (
    <div className="landing__targetMarket">
      <div className="landing__targetMarketContainer">
        <Wrapper>
          <div className="targetMarketTitleAndIcon">
            <span className="targetMarkett__title">Target Market</span>
            {/* <img
              src={require("../../../assets/images/home/About Underline.png")}
              alt=""
            /> */}
          </div>

          <span className="targetMarkett__desc">
            Tocoma Limited can provide solutions before, during and after your
            project is complete. Our business development group and sales
            representatives approach each proj ect with a wealth of technical
            expertise and product knowledge.
          </span>
        </Wrapper>

        <Wrapper2>
          <div className="targetMarket__container21">
            {data.map((item, index) => {
              return (
                <Link
                  to="/target-market"
                  className="targerMartet__content link"
                  key={index}
                >
                  <div className="targerMartet__contentImage">
                    <img src={item?.IMAGE_LINK_2} alt="" />

                    <div className="targerMartet__contentTitle21">
                      <span>{item?.NAME}</span>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </Wrapper2>

        <Wrapper>
          <div className="targetMarket__btn">
            <Link to="/target-market " className="link">
              <TextIconBtn name={"Discover"} />
            </Link>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default LandingTargetMarket;
