import React, { useEffect } from "react";
import "./App.scss";
import "./styles/global.scss";

import { Outlet, Route, Routes, useLocation } from "react-router-dom";

// import LandingPage2 from "./Screens/landingPage/LandingPage2";
import LandingPage from "./Screens/landingPage2/LandingPage";
// import HomePage from "./Screens/homePage/HomePage";
import Header from "./components/header/Header";
import { NavProvider } from "./context/NavContext";
// import Footer from "./components/footer/Footer";

// about pages import
import AboutPage from "./Screens/aboutPage/AboutPage";
import AboutDetailPage from "./Screens/aboutPage/aboutDetailPage/AboutDetailPage";

// partners Pages import
import PartnersPage from "./Screens/partners/partnersPage/PartnersPage";
import PartnerDetailPage from "./Screens/partners/partnerDetailPage/PartnerDetailPage";

// Refecence Pages import
import ReferenceProject from "./Screens/reference/referenceProject/ReferenceProject";
import ReferanceProjectList from "./Screens/reference/referanceProjectList/ReferenceProjectList";
import ReferenceProjectDetails from "./Screens/reference/referenceProjectDetails/ReferenceProjectDetails";

// work with us import
import WorkWithUs from "./Screens/workWithUs/workWithUs/WorkWithUs";
import WorkWithUsAllList from "./Screens/workWithUs/workWithUsAllList/WorkWithUsAllList";
import OpenPositionList from "./Screens/workWithUs/openPositionSingleList/OpenPositionList";
import OpenPositionDetails from "./Screens/workWithUs/openPositionDetails/OpenPositionDetails";
import WorkWithUsFormPage from "./Screens/workWithUs/workWitgUsFormPage/WorkWithUsFormPage";

// news and events import
import NewsAndEventsPage from "./Screens/newsAndEvents/newsAndEventsPage/NewsAndEventsPage";
import NewsEventsDetail from "./Screens/newsAndEvents/newsAndEventsDetails/NewsEventsDetail";

// download page import
import Download from "./Screens/download/Download";
import DownloadPage from "./Screens/download/DownloadPage";

// contact page import
import ContactUs from "./Screens/contactUs/ContactUs";

// product Pages import
import ProductPage from "./Screens/productPages/productPage/ProductPage";
import ProductList1 from "./Screens/productPages/productListPage/ProductList";
import ProductDetail1 from "./Screens/productPages/productDetailsPage/ProductDetailsPage";

// search page import
import SearchPage from "./Screens/searchPage/SearchPage";

// Target Market Pages import
import TargetMarket from "./Screens/targetMarket/TargetMarket";

// error page import
import ErrorPage from "../src/Screens/errorPage/ErrorPage";

export default function App() {
  const location = useLocation();
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    scrollToTop();

    const delay = 500;
    const timeoutId = setTimeout(scrollToTop, delay);

    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <div className="app">
      <NavProvider>
        <Header />

        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/e" element={<HomePage />} /> */}

          {/* target market */}
          <Route path="/target-market" element={<TargetMarket />} />

          {/* about pages */}
          <Route path="/about" element={<Outlet />}>
            <Route index element={<AboutPage />} />
            <Route path=":aboutId" element={<AboutDetailPage />} />
          </Route>

          {/* products */}
          <Route path="/products" element={<Outlet />}>
            <Route index element={<ProductPage />} />
            {/* <Route path=":productCatId" element={<ProductList1 />} /> */}
            <Route
              path="list/:stateId/:productCatId"
              element={<ProductList1 />}
            />
            <Route
              path=":productCatId/:productId"
              element={<ProductDetail1 />}
            />
          </Route>

          {/* reference */}
          <Route path="/reference" element={<Outlet />}>
            <Route index element={<ReferenceProject />} />
            <Route path=":refTypeId" element={<ReferanceProjectList />} />
            <Route
              path=":refTypeId/:refProjectId"
              element={<ReferenceProjectDetails />}
            />
          </Route>

          {/* partners */}
          <Route path="/partners" element={<Outlet />}>
            <Route index element={<PartnersPage />} />
            <Route path=":partnerId" element={<PartnerDetailPage />} />
          </Route>

          {/* news and events */}
          <Route path="/news&events" element={<Outlet />}>
            <Route index element={<NewsAndEventsPage />} />
            <Route path=":newsId" element={<NewsEventsDetail />} />
          </Route>

          {/* Work Wit Us */}
          <Route path="/workWithUs" element={<Outlet />}>
            <Route index element={<WorkWithUs />} />
            <Route path="workWithUs-all" element={<WorkWithUsAllList />} />
            <Route path=":workDeptId" element={<OpenPositionList />} />
            <Route
              path=":workDeptId/:positionId"
              element={<OpenPositionDetails />}
            />
            <Route
              path=":workDeptId/:positionId/:workId3"
              element={<WorkWithUsFormPage />}
            />
          </Route>

          {/* download */}
          {/* <Route path="/download" element={<Download />} /> */}
          <Route path="/download" element={<DownloadPage />} />

          {/* contact us */}
          <Route path="/contactUs" element={<ContactUs />} />

          {/* search */}
          <Route path="/search" element={<SearchPage />} />

          {/* error page */}
          <Route path="*" element={<ErrorPage />} />

          {/* dummy */}
          {/* <Route path="/t" element={<TestPage />} /> */}
        </Routes>

        {/* <Footer /> */}
      </NavProvider>
    </div>
  );
}
