import React from "react";
import "./searchPage.scss";

const SearchPage = () => {
  // get the search query from the url
  const searchQuery = window.location.search.split("=")[1];
  console.log(searchQuery);

  return <div>SearchPage</div>;
};

export default SearchPage;
