import slideImg1 from "../../../assets/images/carouselImg/banner1.png";
import slideImg2 from "../../../assets/images/carouselImg/Banner-Slider02-For-TOCOMA1.jpg";
import slideImg3 from "../../../assets/images/carouselImg/Banner-Slider04-For-TOCOMA.jpg";

const carouselItems = [
  {
    id: 1,
    title:
      "We supply quality products and services for the concrete masonry industry",
    desc: "we create, innovate and install better constructuon products that build a brighter future",
    image: slideImg1,
  },
  {
    id: 2,
    title: "Our products are designed to meet the needs of our customers.",
    desc: "we create, innovate and install better constructuon products that build a brighter future",
    image: slideImg2,
  },
  {
    id: 3,
    title:
      "Delivering quality products and services for the concrete masonry industry",
    desc: "we create, innovate and install better constructuon products that build a brighter future",
    image: slideImg3,
  },
];

export default carouselItems;
