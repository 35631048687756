import React from "react";
import "./landingPartners.scss";

import Wrapper from "../../../components/common/wrapper/Wrapper";
import { Link } from "react-router-dom";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";

const LandingPartners = ({ partners }) => {
  return (
    <div className="landingPartners">
      <Wrapper>
        <div className="landingPartners__container">
          <div className="landingPartnersTitleAndIcon">
            <span className="landingPartners__title">Our Partners</span>
            {/* <img
              src={require("../../../assets/images/home/About Underline.png")}
              alt=""
            /> */}
          </div>

          <div className="landingPage__partnerList__container">
            {partners.map((item, index) => {
              return (
                <Link
                  to={`/partners/${item?.BRAND_ID}`}
                  className="partnerList__card"
                  key={index}
                >
                  <div className="partnerList__card_image">
                    <img src={item.IMAGE_LINK} alt="" />
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="landingPartners__bottom">
            <Link to="/partners" className="link">
              <TextIconBtn name={"Discover"} white={true} />
            </Link>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingPartners;
