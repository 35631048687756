import React, { useEffect, useState } from "react";
import "./aboutTocoma.scss";
import { motion } from "framer-motion";

import parse from "html-react-parser";
import Collapsible from "react-collapsible";

import { getAboutData } from "../../../apis/aboutTocoma.apis";

// import { initialTabs as tabs } from "./ingredients";

const AboutTocoma = () => {
  // api call
  const [data, setData] = useState([]);
  const [collapData, setCollapData] = useState([]);

  useEffect(() => {
    getAboutData()
      .then((res) => {
        const filterData = res.data.filter(
          (item) => item.BANNER_TITLE !== "About TOCOMA"
        );
        setData(filterData);
      })
      .catch((err) => {
        console.log(err);
      });

    getAboutData()
      .then((res) => {
        setCollapData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(data);

  const [bannerTitle, setBannerTitle] = useState([]);
  useEffect(() => {
    const getBannerTitle = () => {
      setBannerTitle(data.map((item) => item.BANNER_TITLE));
    };
    getBannerTitle();
  }, [data]);

  const [indexValue, setIndexValue] = useState(0);
  const setViewDataHandler = (index) => {
    setIndexValue(index);
  };

  return (
    <div className="aboutTocoma">
      <div className="aboutTocoma__contentWrapper">
        <div className="aboutTocoma__contentWrapper__left">
          {bannerTitle.map((item, index) => {
            return (
              <div
                className="aboutTocoma__contentWrapper__left__row"
                onClick={() => setViewDataHandler(index)}
                key={index}
              >
                <div
                  className={
                    indexValue === index
                      ? "aboutTocoma__contentWrapper__left__row__text active"
                      : "aboutTocoma__contentWrapper__left__row__text"
                  }
                >
                  <span>{item}</span>
                </div>
                <div
                  className={
                    indexValue === index
                      ? "aboutTocoma__contentWrapper__left__row__icon active"
                      : "aboutTocoma__contentWrapper__left__row__icon"
                  }
                >
                  <i className="fa-solid fa-angle-right"></i>
                </div>
              </div>
            );
          })}
        </div>

        <div className="aboutTocoma__contentWrapper__right">
          <div className="aboutTocoma__contentWrapper__rightContent">
            {data.length > 0 && (
              <>
                <motion.div
                  key={indexValue}
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -20, opacity: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <div className="acr__image">
                    <img src={data[indexValue]?.BANNER_IMAGE_LINK} alt="" />
                  </div>

                  <div className="acr__text">
                    <span>
                      {/* {parse(data[indexValue].DESCRIPTION.slice(0, 1000))} */}
                      {parse(data[indexValue].DESCRIPTION)}
                    </span>
                  </div>
                </motion.div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="about__collap">
        {collapData.map((item, index) => {
          return (
            <Collapsible
              className="collap__wrapper"
              trigger={
                <div className="collap__trigger">
                  <div className="collap__trigger__title">
                    {item?.BANNER_TITLE}
                  </div>
                </div>
              }
              key={index}
            >
              <div className="collap__content">
                <div className="collap__contentImg">
                  <img src={item?.BANNER_IMAGE_STRING} alt="" />
                </div>
                <span>{parse(item?.DESCRIPTION.slice(0, 500))}</span>
              </div>
            </Collapsible>
          );
        })}
      </div>
    </div>
  );
};

export default AboutTocoma;
