import React, { useEffect, useState } from "react";
import "./referenceProjectList.scss";

import { Link, useParams } from "react-router-dom";
import refProjectData from "./data";

// components import
// import Carousal from "../../../components//commonPageComponent/carousel/Carousal";
import Carousal from "../../../components//commonPageComponent/carousel1/Carousal";
import Footer from "../../../components/footer/Footer";
import Wrapper from "../../../components/common/wrapper/Wrapper";

// apis import
import { getReferenceProjectTypeData } from "../../../apis/reference.apis";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";

const ReferenceProjectList = () => {
  const [referenceProjectTypeData, setReferenceProjectTypeData] = useState([]);
  // const typeId = window.location.pathname.split("/")[2];
  const { refTypeId } = useParams();
  // console.log(typeId);

  useEffect(() => {
    getReferenceProjectTypeData({ id: refTypeId })
      .then((res) => {
        setReferenceProjectTypeData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refTypeId]);

  console.log(referenceProjectTypeData);

  const renderImageCards = () => {
    const blocks = [];

    for (let i = 0; i < referenceProjectTypeData.length; i += 5) {
      const rowData = referenceProjectTypeData.slice(i, i + 5);

      const block = (
        <div className="referenceProjectList__block">
          {rowData?.map((item, index) => (
            <div className="referenceProjectList__card" key={index}>
              <div className="referenceProjectList__cardRow__image">
                <img src={item?.PROJECT_IMAGE} alt={item.alt} />
              </div>

              <div className="referenceProjectList__cardRow__content">
                <div className="referenceProjectList__cardRow__contentLeft">
                  <div className="referenceProjectList__cardRow__contentLeft__title">
                    {item?.REF_PROJECT_TYPE_NAME}
                  </div>

                  <div className="referenceProjectList__cardRow__contentLeft__Location">
                    <i className="fa-solid fa-map-marker-alt"></i>
                    {item?.PROJECT_ADDRESS}
                  </div>
                </div>

                <div className="referenceProjectList__cardRow__contentRight">
                  <Link
                    to={`/reference/${item?.REF_PROJECT_TYPE_ID}/${item.REF_PROJECT_ID}`}
                    className="referenceProjectList__cardRow__contentRightLink link"
                  >
                    <TextIconBtn name="Discover" white={true} />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      );

      blocks.push(block);
    }

    return blocks;
  };

  return (
    <div className="referenceProjectList">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/reference" className="common__headerLinkContainer">
            <span className="linkText">Reference Project</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Reference Project List</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="referenceProjectList__container">
          {referenceProjectTypeData.length > 0 && renderImageCards()}
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default ReferenceProjectList;
