import React, { useEffect, useState } from "react";
import "./referenceProjectDetails.scss";

import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

// components import
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import Footer from "../../../components/footer/Footer";

// apis import
import { getReferenceProjectDetailData } from "../../../apis/reference.apis";

// import projectProfileData from "./projectProfileData";
// import usedProductData from "./usedProductData";

const ReferenceProjectDetails = () => {
  const navigate = useNavigate();
  // const typeId = window.location.pathname.split("/")[2];
  // const pId = window.location.pathname.split("/")[3];

  const { refTypeId } = useParams();
  const { refProjectId } = useParams();
  // console.log("type", typeId);
  // console.log(pId);

  // api call for project details
  const [projectDetailData, setProjectDetailData] = useState({});
  useEffect(() => {
    getReferenceProjectDetailData({ typeId: refTypeId, pId: refProjectId })
      .then((res) => {
        setProjectDetailData(res.data);
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [refTypeId, refProjectId]);
  // console.log("projectDetailData", projectDetailData);

  return (
    <div className="referenceProjectDetails">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/reference" className="common__headerLinkContainer">
            <span className="linkText">Reference Project</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div
            className="common__headerLinkContainer"
            onClick={() => navigate(-1)}
          >
            <span className="linkText">Reference Project List</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Reference Project Details</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="referenceProjectDetails__container">
          <div className="referenceProjectDetails__ImageAndHeader">
            <div className="referenceProjectDetails__Image">
              {projectDetailData.PROJECT_IMAGE && (
                <img
                  src={projectDetailData?.PROJECT_IMAGE}
                  alt="referenceProjectDetails__Image"
                />
              )}
            </div>

            <div className="referenceProjectDetails__Header">
              <span>{projectDetailData?.PROJECT_NAME}</span>
              <div className="referenceProjectDetails__HeaderUnderline"></div>
            </div>
          </div>

          <div className="referenceProjectDetails__projectProfile__Container">
            <div className="referenceProjectDetails__projectProfile__Header">
              <span>Project Profile</span>
              <div className="referenceProjectDetails__projectProfile__HeaderUnderline"></div>
            </div>

            <div className="projectProfile__contentContainer">
              {projectDetailData.PROJECT_OWNER && (
                <div className="projectProfile__content">
                  <div className="projectProfile__contentHeader">
                    <span>Owner</span>
                  </div>
                  <div className="projectProfile__contentDesc">
                    <span>{projectDetailData.PROJECT_OWNER}</span>
                  </div>
                </div>
              )}

              {projectDetailData.PROJECT_ENGINEER && (
                <div className="projectProfile__content">
                  <div className="projectProfile__contentHeader">
                    <span>Engineer</span>
                  </div>
                  <div className="projectProfile__contentDesc">
                    <span>{projectDetailData?.PROJECT_ENGINEER}</span>
                  </div>
                </div>
              )}

              {projectDetailData?.PROJECT_CONTRACTOR && (
                <div className="projectProfile__content">
                  <div className="projectProfile__contentHeader">
                    <span>Contractor</span>
                  </div>
                  <div className="projectProfile__contentDesc">
                    <span>{projectDetailData?.PROJECT_CONTRACTOR}</span>
                  </div>
                </div>
              )}

              {projectDetailData.PROJECT_SUB_CONTRACTOR && (
                <div className="projectProfile__content">
                  <div className="projectProfile__contentHeader">
                    <span>Sub-Contractor</span>
                  </div>
                  <div className="projectProfile__contentDesc">
                    <span>{projectDetailData?.PROJECT_SUB_CONTRACTOR}</span>
                  </div>
                </div>
              )}

              {projectDetailData.PROJECT_CHEMICAL_APPLICATOR && (
                <div className="projectProfile__content">
                  <div className="projectProfile__contentHeader">
                    <span>Chemical Applicator</span>
                  </div>
                  <div className="projectProfile__contentDesc">
                    <span>{projectDetailData.PROJECT_CHEMICAL_APPLICATOR}</span>
                  </div>
                </div>
              )}
            </div>

            {projectDetailData.PROJECT_ADDRESS && (
              <div className="projectProfile__projectLocation">
                <div className="projectProfile__projectLocationHeader">
                  <span>Project Location</span>
                </div>
                <div className="projectProfile__projectLocationText">
                  <span>{projectDetailData.PROJECT_ADDRESS}</span>
                </div>
              </div>
            )}
          </div>

          {projectDetailData.PROJECT_VIDEO_LINK && (
            <div className="projectProfile__videoContainer">
              <div className="projectProfile__video">
                <iframe
                  width="1268"
                  height="713"
                  src="https://www.youtube.com/embed/uRzkBtykr9k"
                  title="10 📈 Largest Construction Companies in the World"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          )}

          <div className="projectProfile__projectUsedProduct__container">
            <div className="projectProfile__projectUsedProduct__Header">
              <span>Product Used In This Project</span>
              <div className="projectProfile__projectUsedProduct__HeaderUnderline"></div>
            </div>

            <div className="projectProfile__projectUsedProduct__contentContainer">
              {projectDetailData?.productList?.map((item, index) => {
                return (
                  <div className="projectProfile__projectUsedProduct__content">
                    <div className="projectProfile__projectUsedProduct__contentHeader">
                      <span>{item.ITEM_NAME}</span>
                    </div>
                    <div className="projectProfile__projectUsedProduct__contentDesc">
                      <span>{parse(item.SHORT_DESCRIPTION)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default ReferenceProjectDetails;
