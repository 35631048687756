import React from "react";
import "./landingReferenceProject.scss";
import { Link } from "react-router-dom";

import Wrapper from "../../../components/common/wrapper/Wrapper";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";

const LandingReferenceProject = ({ referenceProjects }) => {
  return (
    <div className="landingReferenceProject">
      <Wrapper>
        <div className="landingRefProject__container">
          <div className="landingReferenceProjectTitleAndIcon">
            <span className="landingReferenceProject__title">
              Reference Projects
            </span>
            {/* <img
              src={require("../../../assets/images/home/About Underline.png")}
              alt=""
            /> */}
          </div>

          <div className="landingRefProject__itemContainer">
            {referenceProjects.slice(0, 3).map((item, index) => {
              return (
                <Link
                  to={`/reference/${item?.REF_PROJECT_TYPE_ID}/${item?.REF_PROJECT_ID}`}
                  className="landingRefProject__item link"
                  key={index}
                >
                  <div className="landingRefProject__itemImage">
                    <img src={item?.PROJECT_IMAGE} alt="" />
                  </div>

                  <div className="landingRefProject__itemText">
                    <span className="landingRefProject__itemTitle">
                      {item?.REF_PROJECT_TYPE_NAME}
                    </span>

                    <span className="landingRefProject__itemLocation">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {item?.PROJECT_ADDRESS}
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="landingRefProject__itemContainer2">
            {referenceProjects.slice(3, 6).map((item, index) => {
              return (
                <Link
                  to={`/reference/${item?.REF_PROJECT_TYPE_ID}/${item?.REF_PROJECT_ID}`}
                  className="landingRefProject__item link"
                  key={index}
                >
                  <div className="landingRefProject__itemImage">
                    <img src={item?.PROJECT_IMAGE} alt="" />
                  </div>

                  <div className="landingRefProject__itemText">
                    <span className="landingRefProject__itemTitle">
                      {item?.REF_PROJECT_TYPE_NAME}
                    </span>

                    <span className="landingRefProject__itemLocation">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {item?.PROJECT_ADDRESS}
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="landingRefProject__btnContainer">
            <Link to="/reference" className="link">
              <TextIconBtn name={"Explore All"} />
            </Link>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingReferenceProject;
