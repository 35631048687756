import React, { useEffect, useState } from "react";
import "./workWithUs.scss";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

// components import
import Footer from "../../../components/footer/Footer";
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import AboutTocoma1 from "../../../components/commonPageComponent/aboutTocoma/AboutTocoma";
// import FactAndFigures from "../../../components/commonPageComponent/factAndFigures/FactAndFigures";
import Wrapper from "../../../components/common/wrapper/Wrapper";

// image import
import bottomImage from "../../../assets/images/work-with-us/workWithUs/workwithUsBottom.png";

// api calls import
import { getWorkWithUsData } from "../../../apis/workWithUs.apis";
import TextIconBtn from "../../../components/common/buttons/textIconBtn/TextIconBtn";

export default function WorkWithUs() {
  // api call
  const [workWithUsData, setWorkWithUsData] = useState([]);
  useEffect(() => {
    getWorkWithUsData()
      .then((res) => {
        setWorkWithUsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log("workWithUsData", workWithUsData);

  return (
    <div className="workWithUs">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Work With Us</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>
      </Wrapper>

      <div className="workWithUs__about">
        <Wrapper>
          <AboutTocoma1 />
        </Wrapper>
      </div>

      {/* <div className="workWithUs__factAndFigures">
        <FactAndFigures />
      </div> */}

      <Wrapper>
        <div className="workWithUs__container">
          <div className="workWithUs__deptContainer">
            {workWithUsData.map((dept, index) => {
              return (
                <Link
                  to={`/workWithUs/${dept.DEPARTMENT_ID}`}
                  className="workWithUs__deptCard"
                  key={index}
                >
                  <div className="workWithUs__deptCard__left">
                    <div className="workWithUs__deptCard__leftHeader">
                      <span>{dept.DEPARTMENT_NAME}</span>
                    </div>
                    <div className="workWithUs__deptCard__leftDesc">
                      <span>
                        {parse(dept.JOB_SUMMERY.slice(0, 150) + "...")}
                      </span>
                    </div>

                    <div className="workWithUs__deptCard__leftPositionLinkContainer">
                      {dept.positionList.map((position, index) => {
                        return (
                          <Link
                            to={`/work-with-us-1/${position.POSITION_ID}`}
                            className="workWithUs__deptCard__leftPositionLink"
                            key={index}
                          >
                            <div className="workWithUs__deptCard__leftPositionLinkDot"></div>
                            <span>{position.POSITION}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  <div className="workWithUs__deptCard__right">
                    <div className="workWithUs__deptCard__rightImg">
                      <img src={dept.IMAGE_STRING} alt="" />
                    </div>
                  </div>
                </Link>
              );
            })}

            <Link
              to="/workWithUs/workWithUs-all"
              className="workWithUs__deptCard"
            >
              <div className="workWithUs__deptCard__left">
                <div className="workWithUs__deptCard__leftHeader TextGradient">
                  <span>Want to join us?</span>
                </div>
                <div className="workWithUs__deptCard__leftDesc">
                  <span>
                    You are looking for new challenges in the chemical and
                    construction industries, and feel motivated with joining a
                    dynamic and innovative international group.
                  </span>
                </div>
                <div className="workWithUs__deptCard__btn">
                  <Link to="/workWithUs/workWithUs-all">
                    <TextIconBtn name="Discover" />
                  </Link>
                </div>
              </div>
              <div className="workWithUs__deptCard__right">
                <div className="workWithUs__deptCard__rightImg">
                  <img src={bottomImage} alt="" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
}
