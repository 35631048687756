import React, { useEffect, useState } from "react";
import "./newsAndEventsPage.scss";

import { Link } from "react-router-dom";
import parse from "html-react-parser";
// import data from "./data";

// components import
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";

// api import
import { getNewsAndEventsData } from "../../../apis/newsAndEvents.apis";
import { getDemoNews } from "../../../apis/demo.api";
import Footer from "../../../components/footer/Footer";

const NewsAndEventsPage = () => {
  const [newsAndEventsData, setNewsAndEventsData] = useState([]);
  // api call
  useEffect(() => {
    getNewsAndEventsData()
      .then((res) => {
        setNewsAndEventsData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(newsAndEventsData);

  const [demoData, setDemoData] = useState([]);
  useEffect(() => {
    getDemoNews()
      .then((res) => {
        setDemoData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(demoData);

  // formate date
  const formateDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da} ${mo} ${ye}`;
  };

  const [selectedType, setSelectedType] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (selectedType === "All") {
      setFilteredData(newsAndEventsData);
    } else {
      const filterData = newsAndEventsData.filter(
        (item) => item.TYPE === selectedType
      );
      setFilteredData(filterData);
    }
  }, [selectedType, newsAndEventsData]);

  const renderNewsAndEventscards = () => {
    const blocks = [];

    for (let i = 0; i < filteredData.length; i += 9) {
      const rowData = filteredData.slice(i, i + 9);

      const block = (
        <div className="newsAndEvents__content" key={i}>
          {rowData?.map((item, index) => (
            <Link
              to={`/news&events/${item?.ID}`}
              className={
                item.TYPE === "News"
                  ? "newsAndEvents__content__card link"
                  : item.TYPE === "Event"
                  ? "newsAndEvents__content__card eventBg link"
                  : item.TYPE === "Acquisition"
                  ? "newsAndEvents__content__card link"
                  : "newsAndEvents__content__card link"
              }
              key={index}
            >
              <div className="newsAndEvents__content__cardImg">
                <img src={item?.IMAGE_LINK} alt="" />
              </div>
              <div className="newsAndEvents__content__card__container">
                <div className="newsAndEvents__content__cardDateAndtype">
                  <span>
                    {item.TYPE === "News"
                      ? "News"
                      : item.TYPE === "Event"
                      ? "Event"
                      : item.TYPE === "Acquisition"
                      ? "Acquisition"
                      : "General"}
                  </span>
                  <span>{formateDate(item.POSTING_DATE)}</span>
                </div>
                <div className="newsAndEvents__content__card__title">
                  <span> {item?.NEWS_EVENTS_TITLE}</span>
                </div>
                <div className="newsAndEvents__content__card__description">
                  <span>
                    {parse(
                      item?.DESCRIPTION && item?.DESCRIPTION.slice(0, 150)
                    )}{" "}
                    ...
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      );

      blocks.push(block);
    }

    return blocks;
  };

  return (
    <div className="newsAndEventsPage111">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div
            className={
              selectedType === "All"
                ? "common__headerLinkContainer common__headerLinkContainer__active"
                : "common__headerLinkContainer"
            }
            onClick={() => setSelectedType("All")}
          >
            <span className="linkText">All News and Events</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>

          <div
            className={
              selectedType === "News"
                ? "common__headerLinkContainer common__headerLinkContainer__active"
                : "common__headerLinkContainer"
            }
            onClick={() => setSelectedType("News")}
          >
            <span className="linkText">News</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>

          <div
            className={
              selectedType === "Event"
                ? "common__headerLinkContainer common__headerLinkContainer__active"
                : "common__headerLinkContainer"
            }
            onClick={() => setSelectedType("Event")}
          >
            <span className="linkText">Events</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>

          <div
            className={
              selectedType === "Acquisition"
                ? "common__headerLinkContainer common__headerLinkContainer__active"
                : "common__headerLinkContainer"
            }
            onClick={() => setSelectedType("Acquisition")}
          >
            <span className="linkText">Acquisition</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="newsAndEventsPage__container">
          {filteredData.length > 0 && renderNewsAndEventscards()}
        </div>
      </Wrapper>

      <Footer />
    </div>
  );
};

export default NewsAndEventsPage;
