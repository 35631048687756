import axios from "axios";

import { ABOUT_TOCOMA_URL, ABOUT_TOCOMA_HISTORY_URL } from "../utils/urls";

export const getAboutData = () => {
  return axios({
    method: "GET",
    url: ABOUT_TOCOMA_URL,
  });
};

export const getAboutHistoryData = () => {
  return axios({
    method: "GET",
    url: ABOUT_TOCOMA_HISTORY_URL,
  });
};
